import React from 'react';
import { useComponents } from '../../utils/multiBrandedTheme';

const Empty = ({ textBefore, search }) => {
  const { Text, Box, Heading } = useComponents();

  return (
    <Box
      p={8}
      bgColor="grey10"
      borderRadius={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Heading level={5} color="greyBase">
        {textBefore} <Text color="grey80">'{search}'</Text>
      </Heading>
    </Box>
  );
};

export default Empty;
