import SEO from 'components/Seo';
import { Link } from 'gatsby';
import { Flex } from 'CHAMELEON_REACT_HUB';
import ic_about from 'images/ic_about.svg';
import ic_react from 'images/ic_react.svg';
import ic_tokens from 'images/ic_tokens.svg';
import ic_touch from 'images/ic_touch.svg';
import openGraphImage from "../../../../src/images/chameleon_banner.png";
import LinkCard from 'components/LinkCard';
import Banner from 'components/Banner';
import Container from 'components/Container';
import Why from 'components/Why';
import Brands from 'components/Brands';
import Updates from 'components/Updates';
import * as React from 'react';
export default {
  SEO,
  Link,
  Flex,
  ic_about,
  ic_react,
  ic_tokens,
  ic_touch,
  openGraphImage,
  LinkCard,
  Banner,
  Container,
  Why,
  Brands,
  Updates,
  React
};