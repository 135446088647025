import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useComponents } from '../../utils/multiBrandedTheme';

const CopyButton = ({ children, copyText, icon, text }) => {
  const [copied, setCopied] = useState(false);
  const { Button, Box } = useComponents();

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);

      // eslint-disable-next-line
      () => clearTimeout(timeout);
    }
  }, [copied]);

  // Render prop
  if (children && typeof children === 'function') {
    const renderProps = {
      copied,
      copyText,
      text,
      icon,
    };

    return (
      <CopyToClipboard text={copyText} onCopy={() => setCopied(true)}>
        {children(renderProps)}
      </CopyToClipboard>
    );
  }

  return (
    <CopyToClipboard text={copyText} onCopy={() => setCopied(true)}>
      <Box my={2}>
        <Button
          iconLeft={!copied ? icon : undefined}
          size="small"
          width="full"
          appearance="secondary"
        >
          {copied ? 'Copied!' : text}
        </Button>
      </Box>
    </CopyToClipboard>
  );
};

export default CopyButton;
