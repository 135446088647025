import React from 'react';
import { Box, Text } from 'CHAMELEON_REACT_HUB';

const fontFamily = ({ label, fontFamily, fontWeight, lineHeight, fontSize }) => {
  return (
    <Box>
      <Text as="p" mb={3} color="colorBlackBase" size='Heading3' style={{fontFamily, fontWeight, lineHeight, fontSize}}>Aa</Text>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default fontFamily;