import React from 'react';
import PropTypes from 'prop-types';
import humanize from '../../utils/humanizeProp';
import Code from '../Code';
import { useComponents } from '../../utils/multiBrandedTheme';

const renderTypes = types =>
  humanize(types)
    .split(',')
    .map((type, i) => <Code key={`${type}-${i}`}>{type}</Code>);

const renderDefaultValue = defaultProp =>
  defaultProp && defaultProp.value && <Code>{defaultProp.value}</Code>;

const renderData = data =>
  Object.keys(data).map(prop => {
    const item = {};
    item.name = <Code>{prop}</Code>;
    item.type = <React.Fragment>{renderTypes(data[prop].type)}</React.Fragment>;
    item.default = renderDefaultValue(data[prop].defaultValue);
    item.required = data[prop].required && 'true';
    item.description = data[prop].description;
    return item;
  });

const PropsTable = ({ types }) => {
  if (!types) {
    return null;
  }

  const { Table } = useComponents();

  return (
    <Table
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Type', field: 'type' },
        { title: 'Default', field: 'default' },
        { title: 'Required', field: 'required' },
        { title: 'Description', field: 'description' },
      ]}
      data={renderData(types)}
    />
  );
};

const typePropType = PropTypes.shape({
  description: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.shape({
    name: PropTypes.string,
  }),
});

PropsTable.propTypes = {
  types: PropTypes.objectOf(typePropType),
};

PropsTable.defaultProps = {};

export default PropsTable;
