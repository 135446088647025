import React from 'react';
import { useComponents } from '../../utils/multiBrandedTheme';
import { Link } from 'gatsby';

const WideListItemLink = ({ to, title, ...rest }) => {
  const { WideListItem, Text } = useComponents();

  return (
    <WideListItem
      as={to ? Link : 'div'}
      clickable={!!to}
      to={to}
      title={
        <Text size="Caption1" color="grey70">
          {title}
        </Text>
      }
      {...rest}
    />
  );
};

export default WideListItemLink;
