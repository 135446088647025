import React from 'react';
import { Table } from 'CHAMELEON_REACT_HUB';
import { useComponents, useStyleguide } from '../../utils/multiBrandedTheme';

const TextScale = ({ ...props }) => {
  const { Text } = useComponents();
  const styleGuide = useStyleguide('js');
  const fontTokens = styleGuide.filter(token => token.path[0] === 'font');
  const obj = {};

  fontTokens.forEach(item => {
    obj[item.name] = item.value;
  });

  const data = [
    'Heading1',
    'Heading2',
    'Heading3',
    'Heading4',
    'Heading5',
    'Heading6',
    'Paragraph',
    'Caption1',
    'Caption2',
  ].map(size => ({
    size: (
      <Text as="div" size={size} fontFamily="system">
        {size}
        {obj[`font${size}LargeLineHeight`] && (
          <Text color="grey60" size="Caption2">
            Responsive
          </Text>
        )}
      </Text>
    ),
    fontSize: `${obj[`font${size}Size`]} ${
      obj[`font${size}LargeSize`] ? `/ ${obj[`font${size}LargeSize`]}` : ''
    }`,
    lineHeight: `${obj[`font${size}LineHeight`]}${
      obj[`font${size}LargeLineHeight`]
        ? `/ ${obj[`font${size}LargeLineHeight`]}`
        : ''
    }`,
  }));

  return (
    <Table
      {...props}
      columns={[
        { title: 'SIZE', field: 'size' },
        { title: 'FONT-SIZE', field: 'fontSize', textAlign: 'right' },
        { title: 'LINE-HEIGHTS', field: 'lineHeight', textAlign: 'right' },
      ]}
      data={data}
      emptyDescription="No fonts scale found"
    />
  );
};
export default TextScale;
