import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function SvgComponent(props) {
  const brandTheme = useContext(ThemeContext);
  return (
    <svg width="100%" data-name="Layer 1" viewBox="0 0 400 300" {...props}>
      <title>{'#2 problem solve'}</title>
      <path
        d="M264.78 118.27s-3.84 17.51-24.17 19.49-48.75-45.52-48.75-45.52 24.53 2.49 33.47 14.49S243 124.33 257 115z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M264.78 118.27s-3.84 17.51-24.17 19.49-48.75-45.52-48.75-45.52 24.53 2.49 33.47 14.49S243 124.33 257 115z"
        fill="#fff"
        opacity={0.2}
      />
      <ellipse
        cx={205.66}
        cy={258.99}
        rx={100.75}
        ry={13.95}
        fill="#e6e6e6"
        opacity={0.45}
      />
      <path
        d="M190.59 249.22s-1.19 4 1.47 6.33 1.36 5.68-1.73 4.51-7.27-9.33-7.27-9.33l3.23-4zM226.33 246.83s-.67 4.11 2.27 6.09 2.08 5.45-1.14 4.69-8.4-8.32-8.4-8.32l2.68-4.35z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M168.49 61.47s-.39 7.7-2.38 12.59a3.88 3.88 0 01-5.11 2.12c-2.44-1-5.45-3.15-5.74-7.3l-1.36-7A6.92 6.92 0 01158 55c4.72-2.52 11 1.79 10.49 6.47z"
        fill="#f4a28c"
      />
      <path
        fill="#f4a28c"
        d="M154.33 67.85l3.52 20.99 14.24.66-8.28-16.4-9.48-5.25z"
      />
      <path
        d="M166.54 61.52a30.58 30.58 0 01-7-1.51 6.42 6.42 0 01-1 7 5.12 5.12 0 01-3.13 1.82 2.74 2.74 0 01-3-3.17l1.09-7.25a7.87 7.87 0 014.95-6.41 27.58 27.58 0 013.5-1.15c3-.74 6.86 1.72 9.66.16a1.86 1.86 0 012.75 1.61c0 3-1.15 7.68-5.42 8.75a7.15 7.15 0 01-2.4.15z"
        fill="#24285b"
      />
      <path
        d="M159.46 66.88s.35-2.94-1.89-3-2.77 4.17.18 5zM168 66.31l1.72 3.24a1.23 1.23 0 01-1 1.81l-3 .15z"
        fill="#f4a28c"
      />
      <path
        d="M165 75.56a9.43 9.43 0 01-4.93-1s.41 5.52 7.31 5.74z"
        fill="#ce8172"
        opacity={0.31}
      />
      <path
        d="M221 180.17l10.43 16.24a33.23 33.23 0 013.36 29.08l-7.55 21.27-7.83-1.7 2.2-21.16a19.67 19.67 0 00-7-17.15l-15.86-13.25 12-13.33z"
        fill="#24285b"
      />
      <path
        d="M131.78 107.78a21.55 21.55 0 0122.11-20.66c14.4.35 35.63 2.22 48.94 9.61 21.66 12 18 80.74 18 80.74l-46.47 10.93s-43.9-43.4-42.58-80.62z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M152.41 99.61s19.68 8.6 28 43c8.22 34.08 39.07 13.8 39.07 13.8l-.48 21.71-44.64 10.28-9.68-10.65s-29.25-32.21-12.27-78.14z"
        opacity={0.08}
      />
      <path
        d="M174.36 188.4l10.87 16.6a27 27 0 013.49 21.79l-5.61 20.82 8.75 2 11.81-21.49a33.52 33.52 0 001.61-29l-2.51-6.07 14-14.65z"
        fill="#24285b"
      />
      <path
        d="M251.55 155.29l16.56-13.5-16.88-20.71a14.67 14.67 0 10-11.76-14.43l-16.89-20.72-55.85 45.54 45.53 55.85 16.56-13.5a14.66 14.66 0 0122.73-18.53z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M104.43 154.14l-10.43-16-20 13.09a13.13 13.13 0 10-14 9.13l-20 13.1 35.33 54 54-35.33-10.47-16a13.13 13.13 0 11-14.38-22z"
        fill="#e6e6e6"
      />
      <path
        d="M347.83 145.31a13.24 13.24 0 10-14.78-8l-21.21-11.52-9.23 17a13.16 13.16 0 11-12.54 23.1L280.78 183 338 214l9.21-17a13.25 13.25 0 1112.64-23.28l9.21-17z"
        fill="#c9c9c9"
      />
      <path
        d="M187 156.39l40.39.93s-5.07 7.6 1.39 16.5l-16.56 13.5z"
        opacity={0.08}
      />
      <path
        d="M130.78 105c-2.12 10.22-3.91 30.11 8.81 59.62a21.75 21.75 0 0019.85 13.07l59.55.41.45-9.13-54.91-9.14s2.2-46.54-13.19-61.36c-6.9-6.63-18.62-2.84-20.56 6.53z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M130.78 105c-2.12 10.22-3.91 30.11 8.81 59.62a21.75 21.75 0 0019.85 13.07l59.55.41.45-9.13-54.91-9.14s2.2-46.54-13.19-61.36c-6.9-6.63-18.62-2.84-20.56 6.53z"
        fill="#fff"
        opacity={0.2}
      />
      <path
        d="M219.06 176.75s7.21 3.65 10.88.15.17-6 0-9.67a2.34 2.34 0 00-1.62-2.4 3 3 0 00-3.48 1.4c-1 1.62-2.87 4.13-5.4 3.71zM267.5 112.84s-4.43-5.55-9.33 0 5.24 13.61 9.33 0z"
        fill="#f4a28c"
      />
      <path
        d="M164.53 159.85a75.35 75.35 0 00-10.37 0 37.16 37.16 0 0110.37-3.46z"
        opacity={0.08}
      />
      <path
        fill="#c9c9c9"
        d="M136.254 39.067l-4.507-2.827 2.827-4.507 4.507 2.827z"
      />
      <path
        fill="#e6e6e6"
        d="M235.843 43.005l-3.939-3.939 3.939-3.938 3.938 3.938zM82.319 108.364L80.3 99.52l8.843-2.019 2.018 8.843z"
      />
      <path fill="#c9c9c9" d="M299.54 94.03v-9.07h9.07v9.07z" />
    </svg>
  );
}

export default SvgComponent;
