import React from 'react';
import LinkCard from '.';
import Columns from '../Columns';

const Grid = ({ items }) => (
  <Columns>
    {items.map(item => (
      <LinkCard
        key={item.path}
        title={item.title}
        subtitle={item.subtitle}
        to={item.path}
        target={item.target}
        external={item.external}
      />
    ))}
  </Columns>
);

export default Grid;
