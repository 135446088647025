import React from 'react';
import styled from 'styled-components';
import {
  borderRadiusSides,
  colorGrey90,
  colorGreyLight,
  colorWhiteBase,
  space3,
  space5,
} from '@mediahuis/chameleon-theme-hub';

const Wrap = styled.div`
  position: absolute;
  right: 0;
  top: ${space3};
`;

const Circle = styled.button`
  background: ${({ dark }) => (dark ? colorGrey90 : colorWhiteBase)};
  border-radius: ${borderRadiusSides};
  border: 1px solid ${colorGreyLight};
  cursor: pointer;
  height: ${space5};
  margin-right: ${space3};
  width: ${space5};

  &:focus {
    outline: none;
  }
`;

const BackgroundSwitchNavigation = ({ onThemeClick }) => {
  return (
    <Wrap>
      <Circle onClick={() => onThemeClick('light')} />
      <Circle dark onClick={() => onThemeClick('dark')} />
    </Wrap>
  );
};

export default BackgroundSwitchNavigation;
