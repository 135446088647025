import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import styled, { ThemeContext } from 'styled-components';
import {
  borderRadius2,
  colorGrey30,
  colorWhiteBase,
  fontSizeSmall,
  fontSizeMedium,
  space7,
  space8,
  space9,
  colorGrey90,
} from '@mediahuis/chameleon-theme-hub';
import Grid from '../Grid';
import {
  useComponents,
  useIcons,
  useIllustrations,
  useTokens,
} from '../../utils/multiBrandedTheme';
import prismTheme from './prismTheme';
import CopyImport from './CopyImport';
import BackgroundSwitchNavigation from './BackgroundSwitchNavigation';
import { useThemeContext } from '../ThemeContext';

const Wrap = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
  position: relative;
  margin-bottom: ${space8};
`;

const Preview = styled(({ dark, ...rest }) => <LivePreview {...rest} />)`
  padding: ${space9} ${space7};
  border-radius: ${borderRadius2} ${borderRadius2} 0 0;
  border: 1px solid ${colorGrey30};
  border-bottom: 0;
  background: ${props => (props.dark ? colorGrey90 : colorWhiteBase)};

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

const EditorWrapper = styled.div`
  border: 1px solid ${colorGrey30};
`;

const Editor = styled(LiveEditor)`
  font-size: ${fontSizeSmall};
  line-height: 1.5;
`;

const transformCode = (code, wrap) => {
  /** No Jsx as wrapper */
  if (wrap === 'function') {
    return code;
  }

  if (wrap === 'flex') {
    return `<Flex justifyContent="space-around">${code}</Flex>`;
  }

  if (wrap === 'grid') {
    return `<Grid>${code}</Grid>`;
  }

  /** Wrap with jsx div -> you can nest without wrapping in mdx itself */
  return `<div>${code}</div>`;
};

const CodePreview = ({ children }) => {
  const [previewTheme, setPreviewTheme] = React.useState('light');
  const icons = useIcons();
  const illustrations = useIllustrations();
  const tokens = useTokens();
  const components = useComponents();
  const scope = {
    ...icons,
    ...illustrations,
    ...tokens,
    ...components,
    Grid,
  };

  const brandTheme = useContext(ThemeContext);
  const codePreviewTheme = useMemo(() => {
    return prismTheme(brandTheme);
  }, [brandTheme, prismTheme]);

  const hasLanguage =
    children.props.className && children.props.className.includes('language');

  const [theme] = useThemeContext();
  if (children.props.wrap && children.props.wrap === 'none') {
    return (
      <components.Box
        bgColor="colorGrey10"
        as="pre"
        mb={4}
        borderRadius={1}
        color={theme === 'hbvl' ? 'colorSecondaryBase' : 'colorPrimaryBase'}
        p={4}
        m={1}
        mb={6}
        style={{
          fontFamily: 'monospace',
          fontSize: fontSizeMedium,
          overflowX: 'auto',
          lineHeight: 1.2,
        }}
      >
        <code>{children.props.children.trim()}</code>
      </components.Box>
    );
  }

  return (
    <Wrap>
      <BackgroundSwitchNavigation onThemeClick={setPreviewTheme} />
      <LiveProvider
        code={children.props.children.trim()}
        scope={scope}
        transformCode={code => transformCode(code, children.props.wrap)}
        theme={codePreviewTheme}
      >
        {hasLanguage && !children.props.hidePreview && (
          <Preview dark={previewTheme === 'dark'} />
        )}
        <EditorWrapper>
          <CopyImport
            componentName={children.props.componentName}
            icons={children.props.icons}
            tokens={children.props.tokens}
          />
          {!children.props.hideEditor && <Editor padding={space7} />}
        </EditorWrapper>
        <LiveError />
      </LiveProvider>
    </Wrap>
  );
};

CodePreview.propTypes = {
  children: PropTypes.node,
};

export default CodePreview;
