import React from 'react';
import { Text, Box, Divider } from 'CHAMELEON_REACT_HUB';
import Blockquote from '../Blockquote';
import { CopyCode } from '../Code';
import { useThemeContext } from '../ThemeContext';
import FontExample from './FontExample';
import TextScale from './TextScale';
import fontData from '../../config/fonts';

const FontPreview = () => {
  const [theme] = useThemeContext();
  const brandFonts = fontData[theme];
  const { activeVersion, baseUrl } = brandFonts;

  const urlActiveVersion = `${baseUrl}/${activeVersion}/fonts.css`;
  const urlLatestVersion = `${baseUrl}/latest/fonts.css`;

  const urlBrandBase = `${baseUrl}/`;
  const urlActiveVersionBase = `${baseUrl}/${activeVersion}/`;
  const urlLatestVersionBase = `${baseUrl}/latest/`;

  return (
    <>
      <FontExample
        urlBrandBase={urlBrandBase}
        urlActiveVersionBase={urlActiveVersionBase}
        urlLatestVersionBase={urlLatestVersionBase}
        activeVersion={activeVersion}
      />
      <TextScale mt={6} />

      <Box my={7}>
        <Blockquote title={`Using fonts in an application`}>
          <Text display="block" fontFamily="system" mb={2}>
            Loading fonts through the cdn is **deprecated**.
          </Text>

          <Text display="block" fontFamily="system" mb={5}>
            Please import{' '}
            <CopyCode>@mediahuis/chameleon-theme-*/lib/web/fonts.css</CopyCode>
            instead of using this component.
          </Text>

          <Divider mb={5} />

          <Text size="Heading4" fontWeight="bold" mb={5} display="block">
            CDN (Deprecated)
          </Text>

          <Text display="block" fontFamily="system" mb={4}>
            Active: <CopyCode>{urlActiveVersion}</CopyCode>
          </Text>
          <Text display="block" fontFamily="system" mb={4}>
            Latest: <CopyCode>{urlLatestVersion}</CopyCode>
          </Text>
          <Text display="block" fontFamily="system" mb={4}>
            To prevent an update to a font breaking existing layouts, fonts are
            versioned. If a font were to change substantially a new version is
            to be released.
          </Text>
          <Text display="block" fontFamily="system" mb={4}>
            Versioning is present in the url (e.g. `/fonts/v1.0.0/fonts.css`).
            The fonts on this page will always be the latest version.
          </Text>
          <Text display="block" fontFamily="system">
            However, next to a versioned url, there's also a
            `/fonts/latest/fonts.css` version, that will always contain the
            latest version. Be aware that using this URL directly might break
            your layout, and thus should always be used with great caution.
          </Text>
        </Blockquote>
      </Box>
    </>
  );
};

export default FontPreview;
