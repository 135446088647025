import packageJson from '@mediahuis/chameleon-react/package.json';
import { useThemeContext } from '../components/ThemeContext';

export const useComponents = () => {
  const [, , components] = useThemeContext();

  return components;
};

export const useIcons = () => {
  const [, , , icons] = useThemeContext();

  return icons;
};

export const useIllustrations = () => {
  const [, , , , illustrations] = useThemeContext();

  return illustrations;
};

export const useTokens = () => {
  const [, , , , , tokens] = useThemeContext();

  return tokens;
};

export const useStyleguide = platform => {
  const [theme] = useThemeContext();

  if (platform) {
    return require(`@mediahuis/chameleon-theme-${theme}/lib/styleguide/tokens.${platform}.json`);
  }

  return {
    js: require(`@mediahuis/chameleon-theme-${theme}/lib/styleguide/tokens.js.json`),
    scss: require(`@mediahuis/chameleon-theme-${theme}/lib/styleguide/tokens.scss.json`),
    ios: require(`@mediahuis/chameleon-theme-${theme}/lib/styleguide/tokens.ios.json`),
    android: require(`@mediahuis/chameleon-theme-${theme}/lib/styleguide/tokens.android.json`),
  };
};

export const usePackageJson = () => packageJson;
