import {
  colorBlackBase,
  colorGrey10,
  colorGrey60,
} from '@mediahuis/chameleon-theme-hub';

const theme = brandTheme => ({
  plain: {
    fontFamily: 'Fira Code, monospace',
    color: colorBlackBase,
    backgroundColor: colorGrey10,
  },
  styles: [
    {
      types: ['comment'],
      style: {
        color: colorGrey60,
      },
    },
    {
      types: [
        'tag',
        'class-name',
        'boolean',
        'deleted',
        'string',
        'attr-value',
        'builtin',
        'changed',
        'keyword',
        'number',
      ],
      style: {
        color: brandTheme.codePreviewPrimary,
      },
    },
    {
      types: ['attr-name', 'variable', 'function', 'char'],
      style: {
        color: brandTheme.codePreviewSecondary,
      },
    },
  ],
});

export default theme;
