import React from 'react';
import styled from 'styled-components';
import Blockquote from '../Blockquote';
import { Box, Paper, Flex } from 'CHAMELEON_REACT_HUB';
import * as ChameleonReact from '@mediahuis/chameleon-react-bundled/lib';
import { Link } from 'gatsby';
import { useComponents } from '../../utils/multiBrandedTheme';
import { useThemeContext } from '../ThemeContext';

const TagWrap = styled(Box)`
  & > div {
    display: none;
  }

  &:hover > div {
    z-index: 999;
    display: block;
  }
`;

const Tag = ({ children, aliases, ...rest }) => {
  const { Button, Box, Caption } = useComponents();

  return (
    <TagWrap position="relative" mb={3} mr={3}>
      <Button size="small" appearance="secondary" {...rest}>
        {children}
      </Button>
      <Box position="absolute" p={4} bgColor="whiteBase" clone>
        <Paper>
          <Caption>{aliases}</Caption>
        </Paper>
      </Box>
    </TagWrap>
  );
};

const CloneProps = ({ identifier }) => {
  const component = ChameleonReact[identifier];

  if (!(component && component.clonableProps)) {
    return null;
  }

  const [theme] = useThemeContext();
  const { Text, Box, Paragraph } = useComponents();

  return (
    <Box>
      <Text
        as="h3"
        mt={9}
        mb={6}
        color="colorGrey80"
        size="Heading5"
        fontFamily="primary"
        fontWeight="bold"
      >
        System Props
      </Text>
      <Flex flexWrap="wrap" mb={6}>
        {component.clonableProps.length > 0 ? (
          component.clonableProps.map(clonableProp => (
            <Link
              to={`/getting-started/styled-system?brand=${theme}#h-${clonableProp.toLowerCase()}`}
              key={clonableProp}
            >
              <Tag
                key={clonableProp}
                aliases={Box.aliases[clonableProp].join(', ')}
              >
                {clonableProp}
              </Tag>
            </Link>
          ))
        ) : (
          <Paragraph color="secondaryBase">
            This component does not allow any system props.
          </Paragraph>
        )}
      </Flex>

      <Blockquote title="System Props">
        <Paragraph mb={4} fontFamily="primary">
          Next to the properties listed in the propstable, a collection of
          system props can be added to any component for further general styling
          and layouting purposes. These props vary per component.
        </Paragraph>
        <Paragraph fontFamily="primary">
          To read more about the system,{' '}
          <Link to={`/getting-started/styled-system?brand=${theme}`}>
            click <Text fontWeight="bold">here</Text> to check out its dedicated
            page
          </Link>
          .
        </Paragraph>
      </Blockquote>
    </Box>
  );
};

export default CloneProps;
