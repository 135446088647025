import React from 'react';
import { Box, Text } from 'CHAMELEON_REACT_HUB';

const OverlayExample = ({ value, label }) => {
  return (
    <Box>
      <Box mb={3} width="153px" height="32px" style={{background: value}}></Box>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default OverlayExample;