import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Paper, Box, Flex, Icon } from 'CHAMELEON_REACT_HUB';
import {
  space6,
  space8,
  colorSecondaryBase,
} from '@mediahuis/chameleon-theme-hub';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import { useThemeContext } from '../ThemeContext';

const StyledBox = styled(Box)`
  min-height: 240px;
`;

const Badge = styled.figure`
  position: relative;
  display: block;
  width: ${space8};
  max-width: ${space8};
  height: ${space8};
  background-color: ${colorSecondaryBase};
  border-radius: 0 0 45% 45%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BadgeIcon = styled.img`
  display: block;
  height: ${space6};
`;

const Content = ({ badgeIconSrc, title, subtitle }) => {
  const { Heading, Paragraph, Text } = useComponents();
  const { ArrowForward } = useIcons();

  return (
    <StyledBox p={5} clone>
      <Flex flexDirection="column" justifyContent="space-between">
        <Box>
          {badgeIconSrc && (
            <Badge>
              <BadgeIcon src={badgeIconSrc} />
            </Badge>
          )}
          <Box mb={3}>
            <Heading level={4} fontFamily="primary" color="primary100">
              {title}
            </Heading>
          </Box>
          <Paragraph color="grey50">{subtitle}</Paragraph>
        </Box>
        <Box>
          <Text
            as="span"
            fontFamily="system"
            color="colorPrimary70"
            size="Caption1"
          >
            View {title} <Icon as={ArrowForward} size="small" />
          </Text>
        </Box>
      </Flex>
    </StyledBox>
  );
};

const LinkCard = ({
  to,
  title = 'LinkCard Title',
  subtitle = 'LinkCard subtitle',
  badgeIconSrc,
  external,
  mb,
  target,
  ...rest
}) => {
  const [theme] = useThemeContext();
  if (external) {
    return (
      <Box
        mb={mb ? mb : 0}
        borderRadius={1}
        width="full"
        borderWidth={1}
        borderColor="colorGrey20"
        borderStyle="solid"
        clone
      >
        <Paper hoverable={true}>
          <a href={to} target={target || '_self'}>
            <Content
              title={title}
              subtitle={subtitle}
              badgeIconSrc={badgeIconSrc}
            />
          </a>
        </Paper>
      </Box>
    );
  }
  return (
    <Box
      mb={mb ? mb : 0}
      borderRadius={1}
      borderWidth={1}
      borderColor="colorGrey20"
      borderStyle="solid"
      width="full"
      clone
    >
      <Paper hoverable={true}>
        <Link to={`${to}?brand=${theme}`}>
          <Content
            title={title}
            subtitle={subtitle}
            badgeIconSrc={badgeIconSrc}
          />
        </Link>
      </Paper>
    </Box>
  );
};

LinkCard.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  badgeIconSrc: PropTypes.string,
};
export default LinkCard;
