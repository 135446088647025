import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={18} height={20} viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M4 4V1a1 1 0 011-1h12a1 1 0 011 1v14a1 1 0 01-1 1h-3v3c0 .552-.45 1-1.007 1H1.007A1 1 0 010 19L.003 5c0-.552.45-1 1.007-1H4zM2.003 6L2 18h10V6H2.003zM6 4h8v10h2V2H6v2z"
        fill="#919191"
      />
    </svg>
  )
}

export default SvgComponent
