import React from 'react';
import styled from 'styled-components';
import { Box, Text } from 'CHAMELEON_REACT_HUB';

const AnimatedBox = styled(Box)`
  opacity: 1;
  transition: opacity ${props => props.speed}; 

  &:hover {
    opacity: 0;
  }
`;


const MotionExample = ({ value, label }) => {
  return (
    <Box>
      <AnimatedBox mb={3} p={2} borderColor="colorGrey60" borderStyle="solid" borderWidth={1} speed={value}>Hover me!</AnimatedBox>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default MotionExample;