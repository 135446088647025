import React from 'react';
import PropTypes from 'prop-types';
import componentsConfig from '../../config/component-docs.json';
import PropsTable from './PropsTable';
import ClonableProps from './ClonableProps';

const Props = ({ identifier }) => {
  const componentConfig = componentsConfig.find(
    prop => prop.displayName === identifier
  );

  console.log(componentConfig);

  if (!componentConfig) {
    return null;
  }

  return (
    <div>
      <PropsTable types={componentConfig.props} />
      <ClonableProps identifier={identifier} />
    </div>
  );
};

Props.propTypes = {
  identifier: PropTypes.string,
};

export default Props;
