import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function SvgComponent(props) {
  const brandTheme = useContext(ThemeContext);
  return (
    <svg width="100%" data-name="Layer 1" viewBox="0 0 400 300" {...props}>
      <path
        d="M88.34 94.44C64.91 110.57 51 140.17 53.23 171.07c1.12 15.8 7.42 29.46 20.06 39.37 47.57 37.31 245.37 37.88 269-1.35s-17.69-111.31-94-96.55c-48.02 9.3-99.44-59.74-159.95-18.1z"
        fill="#e6e6e6"
        opacity={0.3}
      />
      <ellipse
        cx={202.33}
        cy={249.82}
        rx={170}
        ry={9.46}
        fill="#e6e6e6"
        opacity={0.45}
      />
      <circle
        cx={226.35}
        cy={69.59}
        r={17.06}
        fill={brandTheme.colorSecondaryBase}
      />
      <circle
        cx={226.35}
        cy={69.59}
        r={32.52}
        fill={brandTheme.colorSecondaryBase}
        opacity={0.15}
      />
      <path
        d="M124.18 125.51a22.82 22.82 0 010 6A1.83 1.83 0 01122 133a4 4 0 01-3.29-2.89l-1.25-3.11a3.26 3.26 0 011.29-3.55c1.98-1.62 5.25-.15 5.43 2.06z"
        fill="#f4a28c"
      />
      <path
        fill="#f4a28c"
        d="M117.69 127.52l-1.36 11.85 6.59.27-1.66-8.73-3.57-3.39zM124.36 127.8l1.07 1.36a.58.58 0 01-.32.93l-1.38.32z"
      />
      <path
        d="M121.7 132.81a4.37 4.37 0 01-2.18-1.18 6.81 6.81 0 002.68 4.22z"
        fill="#ce8172"
        opacity={0.31}
      />
      <path
        d="M123.48 125.66a6.26 6.26 0 01-.83.22 5.87 5.87 0 00-1.14.38l-.3.13a1.17 1.17 0 00-.7 1.08 3.21 3.21 0 01-3.17 3.53s-2.85-4.27-.57-7.56a7.59 7.59 0 015.76-3.07 2.73 2.73 0 01.95 5.29z"
        fill="#24285b"
      />
      <path
        d="M120.45 129.49a.89.89 0 00.13-.9c-.2-.49-.6-1.14-1.26-1s-.81 1.19-.34 1.9a.9.9 0 001.47 0z"
        fill="#f4a28c"
      />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M229.69 169.62h100.03v78.94H229.69z"
      />
      <path
        d="M144.89 243.08s1.41 2.47 3.83 2.25 3.46 2 1.42 3-8-.79-8-.79l-.57-4.4zM100.57 243.08s1.42 2.47 3.84 2.25 3.45 2 1.42 3-8.06-.79-8.06-.79l-.57-4.4z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path opacity={0.08} d="M229.69 169.62h100.03v78.94H229.69z" />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M177.57 169.62h73.69v78.94h-73.69z"
      />
      <path fill="#24285b" d="M214.42 206.01h23.86v42.55h-23.86z" />
      <path fill="#fff" d="M266.63 192.32h50.76v27.38h-50.76z" />
      <path opacity={0.08} d="M266.63 192.32h50.76v27.38h-50.76z" />
      <path fill="#fff" d="M191.36 206.95h11v24.78h-11z" />
      <path
        d="M124.55 138.37l6.58.28s22.05 2.77 29.64-5.85 5.31-23.73 5.31-23.73h5.69s12.29 37.39-23.46 47.64a19.12 19.12 0 00-13.08 13.34l-2.93 10.59H96.22l-.73-17.14a17.19 17.19 0 00-7.56-13.59c-8.62-5.76-20.89-18.44-11.78-40.84h6.45s-5.6 32.61 41.95 29.3z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M122.2 143.27s38.6 13.22 42.31-17c0 0-3.33 16.06-32.25 12.47l-7.71-.4z"
        opacity={0.08}
      />
      <path
        d="M96.22 180.64h36.08l14.84 63.08h-6.69l-19.65-46.95a1.18 1.18 0 00-2.26.42l-.22 8.35a59.93 59.93 0 01-10.27 30.15l-5.46 8h-7l5-9.52c5.9-11.56 4.81-24.46.93-36.84z"
        fill="#24285b"
      />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M125.29 41.57h18.28V73.2h-18.28z"
      />
      <circle
        cx={232.18}
        cy={228.06}
        r={2.52}
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        fill="#24285b"
        d="M45.22 105.09l34.6-45.93 39.09 45.93H45.22zM157.41 59.16H79.82l39.09 45.93h77.59l-39.09-45.93z"
      />
      <path
        opacity={0.19}
        d="M157.41 59.16H79.82l39.09 45.93h77.59l-39.09-45.93z"
      />
      <path
        d="M78.6 109.15s-7.86 22 10.85 35.71c9.94 7.27 14.16 12.42 15.93 15.65a76.48 76.48 0 009 13.2l6 6.93H96.22l-.68-15.92a16.62 16.62 0 00-7.61-14.81s-23.53-12.82-11.78-40.84z"
        opacity={0.08}
      />
      <path
        d="M112.84 138.43s7.35-7.2 14.92 0c0 0-5.76 11.29-14.92 0z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M127.76 138.43s-5.78 11.29-14.92 0c0 0 7.35-7.2 14.92 0"
        fill="#fff"
        opacity={0.46}
      />
      <circle cx={79.82} cy={87.96} r={7.94} fill="#fff" />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M173.81 166.7H332.4v9.06H173.81z"
      />
      <path fill="#fff" opacity={0.46} d="M173.81 166.7H332.4v9.06H173.81z" />
      <path opacity={0.08} d="M251.26 166.7h81.14v9.06h-81.14z" />
      <path
        d="M318.49 127.38a6.76 6.76 0 00-6.76-6.76 6.59 6.59 0 00-1.09.09 9.1 9.1 0 00-8-4.8h-.33a10.82 10.82 0 10-21 0h-.33a9.12 9.12 0 100 18.23h31.63v-.06a6.77 6.77 0 005.88-6.7zM72.86 186.47a5.13 5.13 0 00-5.12-5.13 5.44 5.44 0 00-.82.07 6.92 6.92 0 00-6.09-3.63h-.25a8.2 8.2 0 10-15.89 0h-.25a6.91 6.91 0 100 13.81h24v-.05a5.11 5.11 0 004.42-5.07zM85.18 248.76s5.55-8-2.74-10.2 2-13-5.72-14.38-6.43 10-6.43 10-5.33-4.59-9-.73 1.47 8.09 1.47 8.09-7.42 2.56-2.79 7.25z"
        fill="#e6e6e6"
      />
      <path
        d="M74 249.82s3.53-6 8.73-5.29 4.65 5.29 4.65 5.29z"
        fill="#c9c9c9"
      />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M264.25 217.25H320v5h-55.75z"
      />
      <path fill="#fff" opacity={0.46} d="M264.25 217.25H320v5h-55.75z" />
      <path opacity={0.08} d="M264.25 217.25H320v5h-55.75z" />
      <path
        fill="#f4a28c"
        d="M77.13 109.1l-1.15-4.01h5.52v4.01h-4.37zM166.38 109.1l-1.15-4.01h5.52v4.01h-4.37z"
      />
    </svg>
  );
}

export default SvgComponent;
