import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useComponents } from '../../utils/multiBrandedTheme';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SwatchItem = ({ label, value, hex, ...rest }) => {
  const { Caption, Box, Paragraph } = useComponents();
  const [copied, setCopied] = useState(false);

  const C = styled(Caption)`
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `;

  useEffect(() => {
    if (!copied) {
      return;
    }

    const timeout = setTimeout(() => setCopied(false), 1000);

    // clean up side-effect
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <Box {...rest}>
      {label && (
        <Paragraph
          as="h4"
          fontFamily="primary"
          fontWeight="medium"
          color="grey80"
          mb={3}
        >
          {label}
        </Paragraph>
      )}
      <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
        <C title="copy value" color="grey60" fontWeight="regular">
          {copied ? 'Copied!' : value}
        </C>
      </CopyToClipboard>
    </Box>
  );
};

SwatchItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default SwatchItem;
