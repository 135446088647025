import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box, Caption } from 'CHAMELEON_REACT_HUB';

const ColorItem = ({ color, textColor }) => {
  if (!color || !textColor) {
    return null;
  }
  return (
    <Box
      style={{
        gridArea: `c-${color.path[2]}`,
        backgroundColor: color.value,
        color: textColor.value,
      }}
      p={3}
      clone
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Caption>{color.name}</Caption>
        <Caption level={2}>{textColor.name}</Caption>
      </Flex>
    </Box>
  );
};

ColorItem.propTypes = {
  color: PropTypes.shape({
    value: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  textColor: PropTypes.shape({
    value: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
};

export default ColorItem;
