import React from 'react';
import PropTypes from 'prop-types';
import { useThemeContext } from '../ThemeContext';

const Section = ({ brands, exclude, ...rest }) => {
  const [theme] = useThemeContext();

  if (exclude && !exclude.includes(theme)) {
    return <div {...rest} />;
  } else if (brands && brands.includes(theme)) {
    return <div {...rest} />;
  }

  return null;
};

Section.propTypes = {
  brands: PropTypes.arrayOf({
    name: PropTypes.string,
    abbreviation: PropTypes.string,
    active: PropTypes.bool,
  }),
};

export default Section;
