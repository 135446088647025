import React from 'react';
import SVGGrid from '../SVGGrid';
import { useIcons } from '../../utils/multiBrandedTheme';

const IconPreview = () => {
  const icons = useIcons();

  return <SVGGrid items={icons} />;
};

export default IconPreview;
