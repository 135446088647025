const sortByOrder = (a, b) => {
  const orderA = a.order || a.frontmatter.order;
  const orderB = b.order || b.frontmatter.order;

  if (orderA < orderB) {
    return -1;
  }

  if (orderA > orderB) {
    return 1;
  }

  return 0;
};

export default sortByOrder;
