import fontsAz from './fonts.az';
import fontsBeltel from './fonts.beltel';
import fontsDl from './fonts.dl';
import fontsDs from './fonts.ds';
import fontsGva from './fonts.gva';
import fontsHbvl from './fonts.hbvl';
import fontsGe from './fonts.ge';
import fontsHd from './fonts.hd';
import fontsLd from './fonts.ld';
import fontsNhd from './fonts.nhd';
import fontsYc from './fonts.yc';
import fontsHub from './fonts.hub';
import fontsIndo from './fonts.indo';
import fontsMh from './fonts.mh';
import fontsNbLegacy from './fonts.nb-legacy';
import fontsNb from './fonts.nb';
import fontsSw from './fonts.sw';
import fontsTel from './fonts.tel';
import fontsVl from './fonts.vl';
import fontsWl from './fonts.wl';
import fontsLw from './fonts.lw';
import fontsLt from './fonts.lt';
import fontsTc from './fonts.tc';
import fontsCo from './fonts.co';

export default {
  az: fontsAz,
  beltel: fontsBeltel,
  dl: fontsDl,
  ds: fontsDs,
  gva: fontsGva,
  hbvl: fontsHbvl,
  ge: fontsGe,
  hd: fontsHd,
  ld: fontsLd,
  nhd: fontsNhd,
  yc: fontsYc,
  hub: fontsHub,
  indo: fontsIndo,
  mh: fontsMh,
  nb: fontsNb,
  'nb-legacy': fontsNbLegacy,
  sw: fontsSw,
  tel: fontsTel,
  vl: fontsVl,
  wl: fontsWl,
  lw: fontsLw,
  lt: fontsLt,
  tc: fontsTc,
  co: fontsCo,
};
