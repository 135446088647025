import generateUrlWithQueryString from './generateUrlWithQueryString';

const updateQueryString = (theme, hash) => {
  if (window && window.history && history.pushState) {
    const path = generateUrlWithQueryString(theme, hash);
    window.history.pushState({ path }, '', path);
  }
};

export default updateQueryString;
