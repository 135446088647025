import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function SvgComponent(props) {
  const brandTheme = useContext(ThemeContext);
  return (
    <svg width="100%" viewBox="0 0 400 300" fill="none" {...props}>
      <path
        d="M65.46 114.176s-3.22 15.67 12.42 18.24c15.64 2.57 33.76 42.36 33.76 42.36l19.32-11.79s-33.21-44.74-23.21-54.41l-42.29 5.6z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M90.94 70.496s27.25-4 70.22-61.53l15.07 119.57s-46.31-29.36-77.88-17.68c0 0-17.1-18.3-7.41-40.36z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M185.83 129.081c18.764-1.903 31.124-31.57 27.606-66.263-3.518-34.694-21.581-61.276-40.346-59.373-18.764 1.903-31.124 31.57-27.606 66.263 3.518 34.693 21.581 61.276 40.346 59.373z"
        fill="#24285B"
      />
      <path
        opacity={0.18}
        d="M151.36 97.356l30.49-10.25-3.63-35.71-33.36 2.16s-2.68 19.7 6.5 43.8z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M181.851 87.118c5.335-.541 8.849-8.975 7.849-18.838-1-9.863-6.136-17.42-11.471-16.88-5.335.542-8.85 8.976-7.85 18.839 1 9.863 6.136 17.42 11.472 16.879z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M94.17 69.686s-45 10.68-47.52 20.16c-2.52 9.48 5.38 31 16.56 30.61 11.18-.39 34.32-10.53 34.32-10.53s-11.52-22.02-3.36-40.24z"
        fill="#24285B"
      />
      <path
        d="M7.79 215.126l70.11-56.44 21.76 17.33-36.93 56.51-54.94-17.4z"
        fill="#F4A28C"
      />
      <path
        d="M111.06 126.816a13.528 13.528 0 0113.69 8.44c4 9.61 3.85 15.88-6.69 19.35-10.54 3.47-13.08-24.71-13.08-24.71l6.08-3.08z"
        fill="#D68676"
      />
      <path
        d="M101.17 109.456l8.58 18.49 9.06-5.12a4.998 4.998 0 002.19-6.32l-3.46-8.33s-9.79-1.58-16.37 1.28z"
        fill="#24285B"
      />
      <path
        d="M76.16 162.876s3.27-30.93 14.42-34.63c11.15-3.7 24.75 4 18.23 12.28s-14.82-.77-14.82-.77l12.86 25.7-7.19 10.56-23.5-13.14z"
        fill="#F4A28C"
      />
      <path
        d="M107.51 114.606a4.129 4.129 0 014.89 0c1.7 1.23 3.57 3.34 2.75 6.31-1.41 5.11-10.34 11.87-14 9.12-3.08-2.32 1.39-11.77 6.36-15.43z"
        fill="#F4A28C"
      />
      <path
        d="M0 213.156l65.18 21.45 32.35-47.32-34.21-24.17L0 213.156z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        opacity={0.06}
        d="M85.65 178.886l-46.38 47.19 25.91 8.53 32.35-47.32-11.88-8.4z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
