import logosAz from '@mediahuis/chameleon-theme-az/cdn-logos';
import logosBeltel from '@mediahuis/chameleon-theme-beltel/cdn-logos';
import logosDl from '@mediahuis/chameleon-theme-dl/cdn-logos';
import logosDs from '@mediahuis/chameleon-theme-ds/cdn-logos';
import logosGva from '@mediahuis/chameleon-theme-gva/cdn-logos';
import logosHbvl from '@mediahuis/chameleon-theme-hbvl/cdn-logos';
import logosGe from '@mediahuis/chameleon-theme-ge/cdn-logos';
import logosHd from '@mediahuis/chameleon-theme-hd/cdn-logos';
import logosLd from '@mediahuis/chameleon-theme-ld/cdn-logos';
import logosNhd from '@mediahuis/chameleon-theme-nhd/cdn-logos';
import logosYc from '@mediahuis/chameleon-theme-yc/cdn-logos';
import logosHub from '@mediahuis/chameleon-theme-hub/cdn-logos';
import logosIndo from '@mediahuis/chameleon-theme-indo/cdn-logos';
import logosMh from '@mediahuis/chameleon-theme-mh/cdn-logos';
import logosNbLegacy from '@mediahuis/chameleon-theme-nb-legacy/cdn-logos';
import logosNb from '@mediahuis/chameleon-theme-nb/cdn-logos';
import logosSw from '@mediahuis/chameleon-theme-sw/cdn-logos';
import logosTel from '@mediahuis/chameleon-theme-tel/cdn-logos';
import logosVl from '@mediahuis/chameleon-theme-vl/cdn-logos';
import logosLw from '@mediahuis/chameleon-theme-lw/cdn-logos';
import logosCo from '@mediahuis/chameleon-theme-co/cdn-logos';
import logosTc from '@mediahuis/chameleon-theme-tc/cdn-logos';
import logosLt from '@mediahuis/chameleon-theme-lt/cdn-logos';
import logosWl from '@mediahuis/chameleon-theme-wl/cdn-logos';

export default {
  gva: logosGva,
  hbvl: logosHbvl,
  ge: logosGe,
  hd: logosHd,
  ld: logosLd,
  nhd: logosNhd,
  yc: logosYc,
  az: logosAz,
  beltel: logosBeltel,
  dl: logosDl,
  ds: logosDs,
  hub: logosHub,
  indo: logosIndo,
  'nb-legacy': logosNbLegacy,
  mh: logosMh,
  nb: logosNb,
  sw: logosSw,
  tel: logosTel,
  vl: logosVl,
  lw: logosLw,
  lt: logosLt,
  tc: logosTc,
  co: logosCo,
  wl: logosWl,
};
