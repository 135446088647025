import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space5};
  grid-template-columns: ${({ cols }) => (cols && cols.xs ? cols.xs : '1fr')};

  @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointSmall}) {
    grid-template-columns: ${({ cols }) => (cols && cols.sm ? cols.sm : '1fr')};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointMedium}) {
    grid-template-columns: ${({ cols }) =>
      cols && cols.md ? cols.md : '1fr 1fr'};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointLarge}) {
    grid-template-columns: ${({ cols }) =>
      cols && cols.lg ? cols.lg : '1fr 1fr 1fr'};
  }
`;
