import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'CHAMELEON_REACT_HUB';

const FigmaEmbed = ({ url, ...rest }) => {
  return (
    <Box
      style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
      width="100%"
      height="450px"
      src={`https://www.figma.com/embed?embed_host=share&url=${url}&chrome=DOCUMENTATION`}
      allowFullScreen
      {...rest}
      as="iframe"
    />
  );
};

FigmaEmbed.propTypes = {
  url: PropTypes.string.isRequired,
};

FigmaEmbed.defaultProps = {
  my: 3,
};

export default FigmaEmbed;
