import React from 'react';
import canUseDom from '../../utils/canUseDom';
import ThemeContext from './ThemeContext';
import * as huComponents from '@mediahuis/chameleon-react-bundled/lib/index.hub';
import * as hubIcons from '@mediahuis/chameleon-theme-hub/lib/web/icons';
import * as hubIllustrations from '@mediahuis/chameleon-theme-hub/lib/web/illustrations';
import * as hubTokens from '@mediahuis/chameleon-theme-hub';

const noop = () => console.log('noop');

const useThemeContext = () => {
  if (canUseDom()) {
    // eslint-disable-next-line
    return React.useContext(ThemeContext);
  }

  // Fallback
  return ['hub', noop, huComponents, hubIcons, hubIllustrations, hubTokens];
};

export default useThemeContext;
