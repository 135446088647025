const generateUrlWithQueryString = (theme, hash) => {
  if (history.pushState) {
    let h = ``;
    let query = ``;

    if (hash) {
      h = `#${hash.replace('#', '')}`; 
    }

    if (theme) {
      query = `?brand=${theme}`;
    }

    return `${window.location.protocol}//${window.location.host}${window.location.pathname}${query}${h}`;
  }
}

export default generateUrlWithQueryString;