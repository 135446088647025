/* eslint-disable import/extensions, import/no-unresolved, import/no-unassigned-import */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import navConfig from '../config/nav.json';

const getSectionItems = (navItems, parentFolder) =>
  navItems.filter(({ node }) => node.fileAbsolutePath.includes(parentFolder));

const generateNavConfig = ({ edges }) =>
  navConfig.map(({ title, parentFolder, mainPath, external }) => ({
    title,
    items: getSectionItems(edges, parentFolder),
    mainPath,
    external,
  }));

const navQuery = graphql`
  fragment fragmentMenu on MdxEdge {
    node {
      headings {
        depth
        value
      }
      frontmatter {
        name
        menu
        depth
        intro
        order
        category
        title
      }
      parent {
        ... on File {
          name
          relativeDirectory
        }
      }
      fileAbsolutePath
    }
  }
  query NavQuery {
    navItems: allMdx(
      filter: { frontmatter: { menu: { ne: 0 } } }
      sort: { fields: frontmatter___name }
    ) {
      edges {
        ...fragmentMenu
      }
    }
  }
`;

const Query = props => (
  <StaticQuery
    query={navQuery}
    render={({ navItems }) => (
      <Fragment>{props.render(generateNavConfig(navItems))}</Fragment>
    )}
  />
);

Query.propTypes = {
  render: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool,
  ]),
};

export default Query;
/* eslint-enable import/extensions, import/no-unresolved, import/no-unassigned-import */
