import React from 'react';
import { Box, Text } from 'CHAMELEON_REACT_HUB';

const BorderRadiusExample = ({ value, label }) => {
  return (
    <Box>
      <Box mb={3} bgColor="colorGrey60" width="24px" height="24px" style={{borderRadius: value}}></Box>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default BorderRadiusExample;