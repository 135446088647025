import React from 'react';
import { useStyleguide, useComponents } from '../../utils/multiBrandedTheme';
import Blockquote from '../Blockquote';

const Breakpoints = ({ platform }) => {
  const { Paragraph } = useComponents();
  const styleguide = useStyleguide(platform);

  const xs = styleguide.find(obj => obj.name === 'grid-breakpoint-x-small');
  const sm = styleguide.find(obj => obj.name === 'grid-breakpoint-small');
  const md = styleguide.find(obj => obj.name === 'grid-breakpoint-medium');
  const lg = styleguide.find(obj => obj.name === 'grid-breakpoint-large');
  const xl = styleguide.find(obj => obj.name === 'grid-breakpoint-x-large');
  const breakpoints = { xs, sm, md, lg, xl };

  return (
    <Blockquote title="Breakpoints">
      {Object.keys(breakpoints).map(key => {
        const breakpoint = breakpoints[key];
        return (
          <Paragraph key={key}>
            {key}: {breakpoint.value} and up - {breakpoint.comment}
          </Paragraph>
        );
      })}
    </Blockquote>
  );
};

Breakpoints.propTypes = {};

export default Breakpoints;
