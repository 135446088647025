import React, { useEffect, useRef, useState } from 'react';
import * as hubComponents from '@mediahuis/chameleon-react-bundled/lib/index.hub';
import * as hubIcons from '@mediahuis/chameleon-theme-hub/lib/web/icons';
import * as hubIllustrations from '@mediahuis/chameleon-theme-hub/lib/web/illustrations';
import * as hubTokens from '@mediahuis/chameleon-theme-hub/';
import '@mediahuis/chameleon-react-bundled/lib/index.hub.css';

import updateQueryString from '../../utils/updateQueryString';
import { useLocalStorage } from '../../hooks';
import brands from '../../config/brands.json';
import ThemeContext from './ThemeContext';

function usePrevious(value, keepFallback) {
  const ref = useRef();
  useEffect(() => {
    ref.current = keepFallback ? value : ref.current;
  });
  return ref.current;
}

const Provider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('ch-theme', 'hub');
  const [css, setCss] = useState(['hub']);
  const [all, setAll] = useState({
    components: {
      hub: hubComponents,
    },
    icons: {
      hub: hubIcons,
    },
    illustrations: {
      hub: hubIllustrations,
    },
    tokens: {
      hub: hubTokens,
    },
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      const urlParams = new URLSearchParams(window.location.search);
      const brand = urlParams.get('brand');
      const activeBrands = brands.filter(b => b.active);

      if (brand) {
        let brandObject = activeBrands.find(b => b.abbreviation === brand);

        // Check if old abbreviation is used so we can redirect to the real abbreviation
        if (!brandObject) {
          brandObject = activeBrands.find(b => b.logoAbbreviation === brand);
        }

        // Only set the theme if it exists, otherwise go to hub by default
        if (brandObject) {
          setTheme(brandObject.abbreviation);
        }
      }
    }
  }, []);

  React.useMemo(() => {
    if (
      theme &&
      (!all.components[theme] ||
        !all.icons[theme] ||
        !all.illustrations[theme] ||
        !all.tokens[theme])
    ) {
      Promise.all([
        import(`@mediahuis/chameleon-react-bundled/lib/index.${theme}.js`),
        import(`@mediahuis/chameleon-theme-${theme}/lib/web/icons`),
        import(`@mediahuis/chameleon-theme-${theme}/lib/web/illustrations`),
        import(`@mediahuis/chameleon-theme-${theme}/lib/web/tokens.js`),
      ]).then(([module1, module2, module3, module4]) => {
        // setComponents({ ...components, [theme]: module1 });
        // setIcons({ ...icons, [theme]: module2 });

        setAll({
          components: {
            ...all.components,
            [theme]: module1,
          },
          icons: {
            ...all.icons,
            [theme]: module2,
          },
          illustrations: {
            ...all.illustrations,
            [theme]: module3,
          },
          tokens: {
            ...all.tokens,
            [theme]: module4,
          },
        });
      });
    }

    if (theme && !css.includes(theme)) {
      // We want to load our themes dynamically for a performance boost
      import(
        `@mediahuis/chameleon-react-bundled/lib/index.${theme}.css`
      ).then(i => setCss(state => [...state, theme]));
    }
  }, [theme]);

  useEffect(() => {
    updateQueryString(theme);
  }, [theme]);

  const prevTheme = usePrevious(theme, !!all.components[theme]);
  const themeComponents =
    all.components[theme] || all.components[prevTheme] || all.components['hub'];

  const themeIcons =
    all.icons[theme] || all.icons[prevTheme] || all.icons['hub'];

  const themeIllustrations =
    all.illustrations[theme] ||
    all.illustrations[prevTheme] ||
    all.illustrations['hub'];

  const themeTokens =
    all.tokens[theme] || all.tokens[prevTheme] || all.tokens['hub'];

  return (
    <ThemeContext.Provider
      value={[
        theme,
        setTheme,
        themeComponents,
        themeIcons,
        themeIllustrations,
        themeTokens,
      ]}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default Provider;
