import React from 'react';
import styled from 'styled-components';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import { useThemeContext } from '../ThemeContext';
import { Paper } from 'CHAMELEON_REACT_HUB';
import Columns from '../Columns';
import HomePageSection from '../HomePageSection';
import brands from 'config/brands';

const Wrapper = styled(({ active, ...rest }) => <Paper {...rest} />)`
  outline: none;
  overflow: hidden;
  transform: ${({ active, theme }) =>
    active ? `translateY(-${theme.space2})` : `translateY(0)`};

  img {
    transition: ${({ theme }) => theme.motionDurationMedium};
    filter: ${({ active }) => (active ? `grayscale(0%)` : `grayscale(100%)`)};
  }

  &:hover {
    transform: translateY(-${({ theme }) => theme.space2});

    img {
      filter: grayscale(0%);
    }
  }
`;

const BrandCard = ({ brand, active, ...rest }) => {
  const [, setTheme] = useThemeContext();
  const { Heading, Flex, Box, LinkText, Icon } = useComponents();
  const { ArrowForward } = useIcons();

  return (
    <Wrapper
      hoverable
      onClick={() => setTheme(brand.abbreviation)}
      role="button"
      tabIndex="0"
      p={6}
      borderRadius={3}
      position="relative"
      elevation={active ? 1 : 0}
      borderColor="grey20"
      borderStyle="solid"
      borderWidth={1}
      active={active}
      {...rest}
    >
      <Flex
        bgColor="grey10"
        borderRadius={3}
        py={5}
        px={8}
        justifyContent="center"
        alignItems="center"
        height="120px"
      >
        <img
          src={`https://shared.mediahuis.be/logos/${brand.logoAbbreviation ||
            brand.abbreviation}/latest/brand-main.svg`}
        />
      </Flex>
      <Heading fontFamily="primary" color="grey80" mt={5} mb={5} level={4}>
        {brand.name}
      </Heading>
      <LinkText
        as="button"
        onClick={() => setTheme(brand.abbreviation)}
        style={{
          padding: 0,
          background: 'transparent',
        }}
        branded
      >
        Select theme <Icon as={ArrowForward} size="small" />
      </LinkText>
      {active && (
        <Box
          height={2}
          bgColor="primaryBase"
          width="full"
          position="absolute"
          bottom={0}
          left={0}
        />
      )}
    </Wrapper>
  );
};

const Brands = () => {
  const [theme] = useThemeContext();
  const { Heading, Paragraph, Box, Tooltip } = useComponents();
  const activeBrands = brands.filter(brand => brand.active);

  return (
    <HomePageSection>
      <Heading level={2} color="grey80" mb={5} fontFamily="primary">
        Multibranding out of the box
      </Heading>
      <Columns
        cols={{
          md: '1fr',
          lg: '2fr 1fr',
        }}
      >
        <Paragraph color="grey80">
          Chameleon gets its name from its adaptability. With Mediahuis being a
          collection of a multitude of brands, the design system needs to
          support this multibranding out of the box.
        </Paragraph>
      </Columns>
      <Box mt={10}>
        <Columns>
          {activeBrands.map(brand => {
            if (brand.info) {
              return (
                <Tooltip
                  id={`tooltip-${brand.abbreviation}`}
                  label={brand.info}
                  key={brand.abbreviation}
                >
                  <div>
                    <BrandCard
                      brand={brand}
                      active={brand.abbreviation === theme}
                    />
                  </div>
                </Tooltip>
              );
            }
            return (
              <BrandCard
                key={brand.abbreviation}
                brand={brand}
                active={brand.abbreviation === theme}
              />
            );
          })}
        </Columns>
      </Box>
    </HomePageSection>
  );
};

export default Brands;
