module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/builds/frontend/chameleon-docs/node_modules/gatsby-remark-images","id":"25a72a9c-2fca-57bc-aacf-aa94bff673ad","name":"gatsby-remark-images","version":"3.3.14","pluginOptions":{"plugins":[],"maxWidth":700,"backgroundColor":"none"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"intro":"/builds/frontend/chameleon-docs/src/components/Layout/IntroLayout.js","update":"/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js","default":"/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"enableCustomId":true}},{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"backgroundColor":"none"}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"backgroundColor":"none"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chameleon Design System","short_name":"Chameleon","start_url":"/","background_color":"#0b0972","theme_color":"#FF5E59","display":"standalone","icon":"src/images/favicon/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f5dfa2a82ae061cda4b284313ad1ddd0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-56426619-6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
