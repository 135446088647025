const sortByParentName = (a, b) => {
  const nameA = a.node.parent.name;
  const nameB = b.node.parent.name;

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export default sortByParentName;
