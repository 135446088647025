import React, { useState } from 'react';
import NavContext from './NavContext';

const Provider = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <NavContext.Provider value={[show, setShow]}>
      {children}
    </NavContext.Provider>
  );
};

export default Provider;
