import React from 'react';
import { Box } from 'CHAMELEON_REACT_HUB';
import styled from 'styled-components';

export default styled(Box).attrs({
  mx: 'auto',
  px: 5,
})`
  max-width: 1024px;
`;
