import sortByParentName from '../../utils/sortByParentName'; // eslint-disable-line
import sortByOrder from '../../utils/sortByOrder'; // eslint-disable-line
import getLinkFromAbsolutePath from '../../utils/getLinkFromAbsolutePath'; // eslint-disable-line
import convertToSlug from '../../utils/convertToSlug'; // eslint-disable-line

const sortItems = items => {
  const orderedItems = items.map(item => item.node).filter(item => item.frontmatter.order).sort(sortByOrder).map(item => ({'node': item}));  
  const unorderedItems = items.map(item => item.node).filter(item => !item.frontmatter.order).map(item => ({'node': item}));
  return [...orderedItems, ...unorderedItems];
};

export const mapItems = grouped =>
  Object.keys(grouped).map(key => {
    const groupedItems = sortItems(grouped[key].sort(sortByParentName));

    const firstTab = groupedItems[0];
    const title = firstTab.node.frontmatter.name;
    const order = firstTab.node.frontmatter.order;
    const category = firstTab.node.frontmatter.category;
    const tabIntro = firstTab.node.frontmatter.intro || '';
    const maxDepth = firstTab.node.frontmatter.depth || 5;
    const relativeDirectory = firstTab.node.parent.relativeDirectory;
    const path = getLinkFromAbsolutePath(firstTab.node.fileAbsolutePath);

    const tabs = groupedItems.map(tab => {
      const tabTitle = tab.node.parent.name;
      const headings = tab.node.headings
        .filter(heading => heading.depth > 1)
        .filter(heading => heading.depth < maxDepth)
        .map(heading => ({
          ...heading,
          slug: `#${convertToSlug(heading.value)}`,
        }));

      return {
        title: tabTitle,
        path:
          tab.node.parent.name === 'index'
            ? tab.node.parent.relativeDirectory
            : `${tab.node.parent.relativeDirectory}/${tab.node.parent.name}`,
        headings,
      };
    });

    return {
      title,
      order,
      category,
      intro: tabIntro,
      path,
      relativeDirectory,
      tabs,
    };
  });


export const mapCategory = items => {
  let navigation = [];
  items.forEach(( item ) => {
    const category = item.category || 0;
    navigation[category] = navigation[category] || [];
    navigation[category].push(item);
  });
  return navigation;
}