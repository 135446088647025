import React from 'react';
import { useComponents } from '../../utils/multiBrandedTheme';
import WideListItem from './ListItem';

const List = ({ children, items, ...rest }) => {
  const { WideList } = useComponents();
  return (
    <WideList {...rest}>
      {children || items.map(item => <WideListItem {...item} />)}
    </WideList>
  );
};

export default List;
