import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function SvgComponent(props) {
  const brandTheme = useContext(ThemeContext);
  return (
    <svg width="100%" data-name="Layer 1" viewBox="0 0 400 300" {...props}>
      <title>{'#63_user interface_twocolour'}</title>
      <path fill="#e6e6e6" d="M103.49 51.39h219.16v172.74H103.49z" />
      <path fill="#c9c9c9" d="M134.18 162.01h41.88v40.06h-41.88z" />
      <path
        opacity={0.08}
        d="M261.7 88.18h60.95v126.75H261.7zM107.18 97.65s4.89-1.4 6.64 6.07.92 9.1-2.73 11-1.77 14 4.86 13.75 12-5.62 15.25 0 6.52 46.81 20.07 37.91 16.59-43 16.59-43l24.21-.35v26.74l-18.83-.37s-2.43 32.84-28.92 40.18a10.05 10.05 0 00-7 12.78l7.12 21.84h-41v-28.8s-1.9-89.55 3.74-97.75z"
      />
      <ellipse
        cx={108.86}
        cy={257.06}
        rx={74.66}
        ry={10.59}
        fill="#e6e6e6"
        opacity={0.45}
      />
      <path
        d="M53.3 246.48s-7.24-1.94-8.85-8.67c0 0 11.21-2.31 11.58 9.26z"
        fill={brandTheme.colorPrimaryBase}
        opacity={0.58}
      />
      <path
        d="M54.19 245.76s-5.09-8-.68-15.46c0 0 8.55 5.38 4.81 15.46z"
        fill={brandTheme.colorPrimaryBase}
        opacity={0.73}
      />
      <path
        d="M55.49 245.76s2.64-8.45 10.71-10.08c0 0 1.53 5.47-5.19 10.08z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        fill="#24285b"
        d="M50.24 245.59l1.51 10.04h9.24l1.32-10.04H50.24z"
      />
      <circle cx={120.59} cy={63.55} r={3.42} fill="#24285b" />
      <circle
        cx={130.76}
        cy={63.55}
        r={3.42}
        fill={brandTheme.colorPrimaryBase}
      />
      <circle
        cx={140.34}
        cy={63.55}
        r={3.42}
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M108.38 123.3s15.39-2 18.49 10.81 3.53 30.7 15.9 34.26S162 147.2 162 147.2s2.87-6.94 6-7.89 5.3 5.9 0 10.1c0 0-4.46 31.59-28.19 30.48s-31.43-33.67-31.43-56.59z"
        fill="#f4a28c"
      />
      <path
        d="M108.38 123.3s15.39-2 18.49 10.81 3.53 30.7 15.9 34.26S162 147.2 162 147.2s2.87-6.94 6-7.89 5.3 5.9 0 10.1c0 0-4.46 31.59-28.19 30.48s-31.43-33.67-31.43-56.59z"
        opacity={0.08}
      />
      <path
        d="M108.38 123.3s16.53-3.26 18.85 10.1a100.24 100.24 0 006.4 22.31L121 159.8z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M108.38 123.3s16.53-3.26 18.85 10.1a100.24 100.24 0 006.4 22.31L121 159.8z"
        fill="#fff"
        opacity={0.46}
      />
      <path
        d="M107.59 101.59a30.83 30.83 0 012.48 7.86 2.5 2.5 0 01-2.23 2.75 5.52 5.52 0 01-5.48-2.4l-2.9-3.55a4.45 4.45 0 01.19-5.15c1.93-2.86 6.78-2.32 7.94.49z"
        fill="#f4a28c"
      />
      <path
        fill="#f4a28c"
        d="M100.55 107.06l-1.17 16.24h9l-2.7-11.51-5.13-4.73zM108.78 104.49l2 1.31a.79.79 0 010 1.34l-1.66 1z"
      />
      <path
        d="M105.76 112.11a6.06 6.06 0 01-2.93-1.4s-.58 3.52 3.7 4.7z"
        fill="#ce8172"
        opacity={0.31}
      />
      <path
        d="M103.48 106.86s-.69-1.78-2-1.14-.3 3.21 1.62 2.78zM124.62 215.76l7.61 34.57h-4.42l-14.8-34.57h11.61zM99.87 211.56l-8.11 38.77h-4.44l.67-39.04 11.88.27z"
        fill="#f4a28c"
      />
      <path
        d="M132.23 250.33s2.46 3.12 5.6 3 3.6 4.29-1.45 3.83a26.83 26.83 0 01-4.66-.8 8.32 8.32 0 00-4.26.15 1.37 1.37 0 01-1.44-.57c-1.44-1.69 2.09-5.57 2.09-5.57zM87.64 250.33s-2.46 3.12-5.6 3-3.6 4.29 1.46 3.83a26.87 26.87 0 004.65-.8 8.32 8.32 0 014.26.15 1.37 1.37 0 001.44-.57c1.44-1.69-2.09-5.57-2.09-5.57zM108.38 123.3H97.71c-19.62.63-31 22.42-20.59 39.05 5.79 9.25 12.7 21.72 13.16 29.17l35-1.76s-2.65-22.68 3.83-35.64c-.03 0 11.89-20.4-20.73-30.82z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M92.76 135.18s4.34 9 5.38 25.15 25.57-2.93 25.57-2.93l1.12 26.71-35.7 1.89-3-10.82s-9.33-16.76 6.63-40z"
        opacity={0.08}
      />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M106.419 142.428l23.3 2.729-4.216 36.004-23.3-2.73z"
      />
      <path
        fill="#24285b"
        d="M112.845 140.885l10.846 1.27-.617 5.274-10.846-1.27zM124.83 184.11l-35.7 1.88-9.53 36.16 51.6-1.84-6.37-36.2z"
      />
      <path
        opacity={0.08}
        d="M128.27 157.45l-24.78 9.89-1.3 11.11 23.3 2.73 2.78-23.73z"
      />
      <path
        d="M92.46 136.91A7.61 7.61 0 0079 131.77a32.64 32.64 0 00-7.53 21.72c.29 21.17 3.14 44.07 48.79 19l-2.46-6.71s-20.26 9.74-28.72 6.58c-6.24-2.29 2.74-20.92 3.38-35.45z"
        fill="#f4a28c"
      />
      <path
        d="M117.78 165.82s7.61-3.62 10.85 0-1.91 6.86-8.39 6.71z"
        fill="#f4a28c"
      />
      <path
        d="M108.38 99.2l1.56 3.91-6.46 2.47s-.36 4.34 1.92 5.13a3.91 3.91 0 01-2.77-1.34 1.56 1.56 0 00-2.16-.14l-.08.08a13.91 13.91 0 01-3.76-6.65c-.73-3.82 3.54-4.41 3.54-4.41s5.67-3.98 8.21.95z"
        fill="#24285b"
      />
      <path
        d="M100.17 98.25s-.3-3.68-6.92-3.1 0 9.54-5.45 17.88c0 0 10-2.12 10.31-11.1z"
        fill="#24285b"
      />
      <path
        d="M100.59 98a3 3 0 00-3.21.28c-1.88 1.18-2.71 5.52.25 7 0-.01-2.45-5.88 2.96-7.28z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M89.57 159.8H70.13s-2.13-28.38 14-32 5.44 32 5.44 32z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M89.57 159.8H70.13s-2.13-28.38 14-32 5.44 32 5.44 32z"
        fill="#fff"
        opacity={0.46}
      />
      <path fill="#c9c9c9" d="M133.63 79.88h89.03v30.41h-89.03z" />
      <circle cx={154.19} cy={95.09} r={10.15} fill="#fff" />
      <path fill="#fff" d="M173.3 88.34h33.91v5.05H173.3z" />
      <path fill="#fff" opacity={0.45} d="M173.3 96.78h33.91v5.05H173.3z" />
      <path
        fill={brandTheme.colorPrimaryBase}
        d="M153.28 120.82h29.17v24.33h-29.17z"
      />
      <path
        fill="#fff"
        opacity={0.62}
        d="M156.32 141.38l6.95-12.4 4.86 9.18 5.91-7.79 5.91 11.01h-23.63z"
      />
      <circle cx={169.22} cy={127.59} r={2.31} fill="#fff" />
      <path fill="#f2f2f2" d="M247.45 75.32h95.5v126.75h-95.5z" />
      <path fill="#c9c9c9" d="M256.53 84.94h36v28.13h-36z" />
      <path fill="#c9c9c9" opacity={0.74} d="M297.86 84.94h36v28.13h-36z" />
      <path fill="#c9c9c9" opacity={0.47} d="M256.53 118.92h36v28.13h-36z" />
      <path fill="#c9c9c9" opacity={0.21} d="M297.86 118.92h36v28.13h-36z" />
      <path
        fill={brandTheme.colorSecondaryBase}
        d="M278.2 159.6h34v14.96h-34z"
      />
      <path fill="#fff" opacity={0.46} d="M293.09 161.69h14.75v3.85h-14.75z" />
      <path fill="#fff" d="M285.2 181.24h22.25v4.46H285.2z" />
      <path fill="#c9c9c9" d="M186.73 162.01h41.88v40.06h-41.88z" />
    </svg>
  );
}

export default SvgComponent;
