import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function SvgComponent(props) {
  const brandTheme = useContext(ThemeContext);
  return (
    <svg
      height="100%"
      width="100%"
      data-name="Layer 1"
      viewBox="0 0 400 300"
      {...props}
    >
      <path
        d="M86.19 279.55s-32.31 2.26-45.59-3.19-3.75-21.1 9.19-24.51 20.77 15.32 26.55-2 20.88-25.95 30.53-14.17 19.52 15.53 29.73 5 19.62-1.93 23.76 13.5 52.15-14.18 70.54-8.4 32.34 22.81 43.57 4.43 24.51-23.15 32-12.26 9.87 23.83 23.15 14 31.32-1 26.21 10.55-39.33 15.48-39.33 15.48-198.68-9.88-230.31 1.57z"
        fill="#e6e6e6"
        opacity={0.42}
      />
      <path
        d="M183.06 221s7.16 23.93-6.8 31-22.38-3.94-36.94 5.76-6.89-24-29.1-22.47-13.88 19.11-13.88 19.11-19.14-18.66-29.06-2.4-5.13 31.54 39.59 26.94 77.53 1.66 116.27.83 110 6.83 113.8-14.61-16.09-30.95-36.77-18.93-17.22-15.68-41.16-3-52.26 27.28-59.5-28.8z"
        fill="#e6e6e6"
      />
      <path
        d="M137.71 17.24C86.45 28.53 52.29 78 61.76 129.63c4.51 24.64 19 49.09 55.41 60.51 87.38 27.4 185.87 13.73 203.23-40.2s6.13-88.66-36.25-115.72c-27.38-17.48-92.89-28.78-146.44-16.98z"
        fill="#e6e6e6"
        opacity={0.3}
      />
      <path
        d="M348.58 102.92a7.76 7.76 0 00-7.77-7.76 7.45 7.45 0 00-1.25.11 10.44 10.44 0 00-9.22-5.52h-.38a12.42 12.42 0 10-24.08 0h-.38a10.47 10.47 0 000 20.94h36.32v-.08a7.76 7.76 0 006.76-7.69zM95.79 152.9a7.9 7.9 0 00-7.9-7.89 7.47 7.47 0 00-1.26.11 10.64 10.64 0 00-9.38-5.61h-.39a12.34 12.34 0 00.39-3.06 12.64 12.64 0 00-25.27 0 12.82 12.82 0 00.39 3.06h-.39a10.65 10.65 0 000 21.29h36.94v-.07a7.9 7.9 0 006.87-7.83z"
        fill="#e6e6e6"
      />
      <circle
        cx={124.49}
        cy={63.01}
        r={15.85}
        fill={brandTheme.colorSecondaryBase}
      />
      <circle
        cx={124.49}
        cy={63.01}
        r={25.67}
        fill={brandTheme.colorSecondaryBase}
        opacity={0.22}
      />
      <path
        d="M164.8 79.62l-.91 2.23a30 30 0 00-2.26 11.42v108.64H173V93.27a30 30 0 00-2.26-11.42l-.91-2.23a2.71 2.71 0 00-5.03 0z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        d="M198.21 30.61l-.11-.11a3.36 3.36 0 00-4.76 0l-.11.11a50.32 50.32 0 00-14.41 34.94v139.84a3.06 3.06 0 003.18 2.92h27.44a3.06 3.06 0 003.18-2.92V65.55a50.32 50.32 0 00-14.41-34.94z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        opacity={0.08}
        d="M199.84 97.08l12.78 6.01v55.41l-23.08-30.71 10.3-30.71z"
      />
      <path
        d="M197.39 94l-.07-.08a2.14 2.14 0 00-3.2 0l-.07.08a38.51 38.51 0 00-9.67 25.52v102.14a2.13 2.13 0 002.13 2.14h18.41a2.14 2.14 0 002.14-2.14V119.52A38.51 38.51 0 00197.39 94z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        d="M197.39 94l-.07-.08a2.14 2.14 0 00-3.2 0l-.07.08a38.51 38.51 0 00-9.67 25.52v102.14a2.13 2.13 0 002.13 2.14h18.41a2.14 2.14 0 002.14-2.14V119.52A38.51 38.51 0 00197.39 94z"
        fill="#fff"
        opacity={0.32}
      />
      <path
        d="M226.64 79.62l.91 2.23a30 30 0 012.25 11.42v108.64h-11.34V93.27a30.19 30.19 0 012.25-11.42l.92-2.23a2.71 2.71 0 015.01 0z"
        fill={brandTheme.colorSecondaryBase}
      />
      <path
        fill={brandTheme.colorPrimary90}
        d="M218.46 120.54h-5.84v-17.45h5.84z"
      />
      <path opacity={0.08} d="M229.81 126.52h-11.34V97.08h11.34z" />
      <path
        d="M184.38 130.67l-7.48 36.72A41.93 41.93 0 01165.2 189l-13.78 13.54a11.67 11.67 0 00-3 5l-1.42 4.81a4.78 4.78 0 004.13 6.11l33.27 3.2zM207.06 130.67l7.47 36.72A42 42 0 00226.24 189L240 202.49a11.67 11.67 0 013 5l1.43 4.85a4.78 4.78 0 01-4.13 6.11l-33.27 3.2zM172.98 103.09h5.84v17.45h-5.84z"
        fill={brandTheme.colorPrimary90}
      />
      <path opacity={0.08} d="M161.63 97.08h11.34v29.44h-11.34z" />
      <path
        d="M191.17 198.71l-6.38 8.86a19.45 19.45 0 00-3.68 11.4v13.12h29.22V219a19.52 19.52 0 00-3.68-11.4l-6.38-8.86a5.6 5.6 0 00-9.1-.03zM189.55 110.82h11.84v4.8h-11.84z"
        fill={brandTheme.colorPrimary90}
      />
      <rect
        x={188.19}
        y={50.41}
        width={15.05}
        height={35.77}
        rx={7.52}
        fill="#fff"
        opacity={0.34}
      />
    </svg>
  );
}

export default SvgComponent;
