import { useState, useEffect } from 'react';
import createFocusTrap from 'focus-trap';

const toggleTrap = (trap, active) => {
  if (active) {
    trap.activate();
  } else {
    trap.deactivate();
  }
};

/**
 * React Hook for trapping the focus inside a particular DOM element. Useful for building modal
 * components.
 *
 * @param {HTMLElement} element The element to trap focus inside of
 * @param {boolean} active Whether or not to activate the trap
 */
const useFocusTrap = (element, active = false, options) => {
  const [trap, setTrap] = useState();

  useEffect(() => {
    // If we've already created a trap, toggle it based on the active status
    if (trap) {
      toggleTrap(trap, active);
      // Otherwise, if there's no trap, but there is a valid element that needs to be trapped
    } else if (element) {
      // Create the trap and store a reference to it
      const newTrap = createFocusTrap(element, {
        clickOutsideDeactivates: true,
        // Set initial focus to the modal wrapper itself instead of focusing on the first
        // focusable element by default
        initialFocus: element,

        ...options,
      });
      setTrap(newTrap);
      // And toggle it based on active status
      toggleTrap(newTrap, active);
    }

    // When the component unmounts, we deactivate the trap, if there is one
    return () => {
      if (trap) {
        trap.deactivate();
      }
    };
  }, [element, active, trap]);
};

export default useFocusTrap;
