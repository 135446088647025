import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import IconCopy from './IconCopy';
import useTimeout from '../../hooks/useTimeout';

import { Flex, Box } from 'CHAMELEON_REACT_HUB';
import {
  space2,
  space3,
  space7,
  fontSizeSmall,
  colorGrey20,
  colorGrey90,
} from '@mediahuis/chameleon-theme-hub';

const Wrap = styled(Flex)`
  position: relative;
  padding: ${space3} ${space7};
  background-color: ${colorGrey20};
  color: ${colorGrey90};
  font-family: 'Fira Code', monospace;
  font-size: ${fontSizeSmall};
`;

const Button = styled(Box)`
  padding: ${space2};
  margin-left: ${space3};
  cursor: pointer;
  border: 0;
  background: transparent;
`;

const generateCopyText = (componentName, icons, tokens) => {
  let imports = '';

  if (icons) {
    imports += `import { ${icons
      .split(',')
      .join(', ')} } from '@mediahuis/chameleon-theme-wl/icons';\n`;
  }

  if (tokens) {
    imports += `import { ${tokens
      .split(',')
      .join(', ')} } from '@mediahuis/chameleon-theme-wl';\n`;
  }

  if (componentName) {
    imports += `import { ${componentName
      .split(',')
      .join(', ')} } from '@mediahuis/chameleon-react';`;
  }

  return imports;
};

const CopyImport = ({ componentName, icons, tokens }) => {
  const [text, setText] = useState(
    generateCopyText(componentName, icons, tokens)
  );
  const [messageShow, setMessageShow] = React.useState(false);

  useTimeout(
    () => {
      setText(generateCopyText(componentName, icons));
      setMessageShow(false);
    },
    messageShow ? 2500 : null
  );

  function textCopied() {
    setMessageShow(true);
    setText('Copied!');
  }

  if (!componentName) {
    return null;
  }

  return (
    <CopyToClipboard text={text}>
      <Wrap justifyContent="space-between" alignItems="center">
        <div>
          {text.split(';').map(t => (
            <Box my={2} key={t}>
              {t}
            </Box>
          ))}
        </div>
        <Button as="button" onClick={textCopied}>
          <IconCopy />
        </Button>
      </Wrap>
    </CopyToClipboard>
  );
};

CopyImport.propTypes = {
  componentName: PropTypes.string,
  icons: PropTypes.string,
  tokens: PropTypes.string,
};

export default CopyImport;
