import React from 'react';
import SVGGrid from '../SVGGrid';
import { useIllustrations } from '../../utils/multiBrandedTheme';

const IllustrationPreview = () => {
  const illustrations = useIllustrations();
  const items = { ...illustrations };
  
  // We don't want to show AvatarPlaceholder because it's just used in the placeholder component
  delete items['AvatarPlaceholder'];

  return (
    <SVGGrid
      items={items}
      cols={{
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr',
        xs: '1fr',
      }}
      height="300px"
      type="illustration"
  />);
};

export default IllustrationPreview;
