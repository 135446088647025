import React from 'react';
import styled from 'styled-components';
import {
  space4,
  space5,
  space6,
  borderRadius2,
} from '@mediahuis/chameleon-theme-hub';
import { Flex } from 'CHAMELEON_REACT_HUB';
import { useComponents } from '../../utils/multiBrandedTheme';
import note from './images/note.svg';

const Blockquote = ({ children, title, ...rest }) => {
  const { Box, Heading, Image } = useComponents();

  const Container = styled(Box)`
    max-width: 668px;

    &::before {
      content: '';
      position: absolute;
      top: ${space5};
      left: -${space4};
      width: 100%;
      height: 100%;
      background-color: ${({ theme: { colorPrimary10 } }) => colorPrimary10};
      border: 1px solid ${({ theme: { colorPrimary20 } }) => colorPrimary20};
      border-radius: ${borderRadius2};
      z-index: -1;
    }
  `;

  return (
    <Flex {...rest}>
      <Container
        position="relative"
        bgColor="colorWhiteBase"
        ml={4}
        mb={6}
        p={6}
        borderRadius={2}
        borderWidth={2}
        borderStyle="solid"
        borderColor="colorPrimary90"
      >
        <Flex>
          <Box mt={2} mr={6} flexShrink="0">
            <img
              src={note}
              alt=""
              width={6}
              style={{ width: space6, height: 'auto', maxWidth: '100%' }}
            />
          </Box>
          <Box>
            {title && (
              <Heading fontFamily="primary" level={3} mt={2} mb={5}>
                {title}
              </Heading>
            )}
            {children}
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Blockquote;
