import React from 'react';
import { Box, Text } from 'CHAMELEON_REACT_HUB';
import isWhite from '../../utils/isWhite'; // eslint-disable-line

const ColorExample = ({ label, value }) => {
  return (
    <Box>
      <Box mb={3} style={{background: value}} width="153px" height="32px" borderColor="colorBlackBase" borderStyle="solid" borderWidth={isWhite(value) ? 1 : 0 }></Box>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default ColorExample;