import brands from "../../../../src/config/brands.json";
import Grid from 'components/LinkCard/Grid';
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
import * as React from 'react';
export default {
  brands,
  Grid,
  List,
  ListItem,
  React
};