import React from 'react';
import { Link } from 'gatsby';
import { useComponents } from '../../utils/multiBrandedTheme';
import Container from '../Container';
import Shape from './Shape';
import BannerIllustration from './BannerIllustration';

const Intro = () => {
  const { Heading, Box, Text, Flex, Button, Hidden } = useComponents();
  return (
    <div>
      <Box position="absolute" right={0}>
        <Shape />
      </Box>
      <Container position="relative">
        <Hidden below="lg">
          <Box position="absolute" right={0} top={10}>
            <BannerIllustration />
          </Box>
        </Hidden>
        <Flex
          height="700px"
          flexDirection="column"
          justifyContent="center"
          position="relative"
        >
          <Box width="413px" style={{ maxWidth: '100%' }}>
            <Heading color="grey80" mb={5} level={1} fontFamily="primary">
              Chameleon <br />
              Design System
            </Heading>
            <Text
              mb={6}
              size="Heading5"
              as="p"
              fontFamily="system"
              color="grey60"
            >
              Chameleon is a multibranded design system providing ready to use
              tools and components for building high-quality, consistent user
              experiences at Mediahuis.
            </Text>
            <Flex>
              <Button as={Link} to="getting-started" mr={5}>
                Get Started
              </Button>
              <Button as={Link} to="components" appearance="secondary">
                Components
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </div>
  );
};

export default Intro;
