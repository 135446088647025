import React, { useState } from 'react';
import Query from '../../queries/navQuery';
import LinkCard from '../LinkCard';
import groupBy from '../../utils/groupBy';
import { mapItems } from '../SideNav/utils';

import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import Columns from '../Columns';
import Empty from '../Empty';
import sortByOrder from '../../utils/sortByOrder';

const PagesOverview = ({ ParentPage, filtered }) => {
  const [search, setSearch] = useState('');

  const { TextField } = useComponents();
  const { Search } = useIcons();

  return (
    <Query
      render={menuItems => {
        const components =
          menuItems.find(item => item.title === ParentPage) || {};
        const grouped = groupBy(
          components.items,
          item => item.node.parent.relativeDirectory
        );
        const mapped = mapItems(grouped);

        const hasOrder = mapped.find(item => item.order);

        if (hasOrder) {
          mapped.sort(sortByOrder);
        }

        if (filtered) {
          const filteredValues = mapped.filter(item =>
            item.title.toLowerCase().includes(search.toLowerCase())
          );

          return (
            <React.Fragment>
              <TextField
                label="Search components"
                labelHidden
                iconLeft={Search}
                id="search-components"
                placeholder="Search components..."
                onChange={e => setSearch(e.target.value)}
                mb={7}
              />
              {filteredValues.length === 0 ? (
                <Empty textBefore="No components matching: " search={search} />
              ) : (
                <Columns>
                  {filteredValues.map(component => (
                    <LinkCard
                      key={component.path}
                      title={component.title}
                      subtitle={component.intro}
                      to={component.path}
                    />
                  ))}
                </Columns>
              )}
            </React.Fragment>
          );
        }

        return (
          <Columns>
            {mapped.map(component => {
              return (
                <LinkCard
                  key={component.path}
                  title={component.title}
                  subtitle={component.intro}
                  to={component.path}
                />
              );
            })}
          </Columns>
        );
      }}
    />
  );
};

export default PagesOverview;
