// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-mdx": () => import("./../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-mission-index-mdx": () => import("./../src/pages/about/mission/index.mdx" /* webpackChunkName: "component---src-pages-about-mission-index-mdx" */),
  "component---src-pages-about-multibranding-index-mdx": () => import("./../src/pages/about/multibranding/index.mdx" /* webpackChunkName: "component---src-pages-about-multibranding-index-mdx" */),
  "component---src-pages-about-team-index-mdx": () => import("./../src/pages/about/team/index.mdx" /* webpackChunkName: "component---src-pages-about-team-index-mdx" */),
  "component---src-pages-about-tiers-index-mdx": () => import("./../src/pages/about/tiers/index.mdx" /* webpackChunkName: "component---src-pages-about-tiers-index-mdx" */),
  "component---src-pages-about-vision-index-mdx": () => import("./../src/pages/about/vision/index.mdx" /* webpackChunkName: "component---src-pages-about-vision-index-mdx" */),
  "component---src-pages-components-accordion-code-mdx": () => import("./../src/pages/components/accordion/code.mdx" /* webpackChunkName: "component---src-pages-components-accordion-code-mdx" */),
  "component---src-pages-components-accordion-design-mdx": () => import("./../src/pages/components/accordion/design.mdx" /* webpackChunkName: "component---src-pages-components-accordion-design-mdx" */),
  "component---src-pages-components-autocomplete-code-mdx": () => import("./../src/pages/components/autocomplete/code.mdx" /* webpackChunkName: "component---src-pages-components-autocomplete-code-mdx" */),
  "component---src-pages-components-autocomplete-design-mdx": () => import("./../src/pages/components/autocomplete/design.mdx" /* webpackChunkName: "component---src-pages-components-autocomplete-design-mdx" */),
  "component---src-pages-components-avatar-code-mdx": () => import("./../src/pages/components/avatar/code.mdx" /* webpackChunkName: "component---src-pages-components-avatar-code-mdx" */),
  "component---src-pages-components-avatar-design-mdx": () => import("./../src/pages/components/avatar/design.mdx" /* webpackChunkName: "component---src-pages-components-avatar-design-mdx" */),
  "component---src-pages-components-banner-code-mdx": () => import("./../src/pages/components/banner/code.mdx" /* webpackChunkName: "component---src-pages-components-banner-code-mdx" */),
  "component---src-pages-components-banner-design-mdx": () => import("./../src/pages/components/banner/design.mdx" /* webpackChunkName: "component---src-pages-components-banner-design-mdx" */),
  "component---src-pages-components-box-code-mdx": () => import("./../src/pages/components/box/code.mdx" /* webpackChunkName: "component---src-pages-components-box-code-mdx" */),
  "component---src-pages-components-brandedheading-code-mdx": () => import("./../src/pages/components/brandedheading/code.mdx" /* webpackChunkName: "component---src-pages-components-brandedheading-code-mdx" */),
  "component---src-pages-components-brandedheading-design-mdx": () => import("./../src/pages/components/brandedheading/design.mdx" /* webpackChunkName: "component---src-pages-components-brandedheading-design-mdx" */),
  "component---src-pages-components-button-code-mdx": () => import("./../src/pages/components/button/code.mdx" /* webpackChunkName: "component---src-pages-components-button-code-mdx" */),
  "component---src-pages-components-button-design-mdx": () => import("./../src/pages/components/button/design.mdx" /* webpackChunkName: "component---src-pages-components-button-design-mdx" */),
  "component---src-pages-components-caption-code-mdx": () => import("./../src/pages/components/caption/code.mdx" /* webpackChunkName: "component---src-pages-components-caption-code-mdx" */),
  "component---src-pages-components-caption-design-mdx": () => import("./../src/pages/components/caption/design.mdx" /* webpackChunkName: "component---src-pages-components-caption-design-mdx" */),
  "component---src-pages-components-checkbox-code-mdx": () => import("./../src/pages/components/checkbox/code.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-code-mdx" */),
  "component---src-pages-components-checkbox-design-mdx": () => import("./../src/pages/components/checkbox/design.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-design-mdx" */),
  "component---src-pages-components-choice-code-mdx": () => import("./../src/pages/components/choice/code.mdx" /* webpackChunkName: "component---src-pages-components-choice-code-mdx" */),
  "component---src-pages-components-choice-design-mdx": () => import("./../src/pages/components/choice/design.mdx" /* webpackChunkName: "component---src-pages-components-choice-design-mdx" */),
  "component---src-pages-components-datepicker-code-mdx": () => import("./../src/pages/components/datepicker/code.mdx" /* webpackChunkName: "component---src-pages-components-datepicker-code-mdx" */),
  "component---src-pages-components-datepicker-design-mdx": () => import("./../src/pages/components/datepicker/design.mdx" /* webpackChunkName: "component---src-pages-components-datepicker-design-mdx" */),
  "component---src-pages-components-datetime-code-mdx": () => import("./../src/pages/components/datetime/code.mdx" /* webpackChunkName: "component---src-pages-components-datetime-code-mdx" */),
  "component---src-pages-components-dialog-code-mdx": () => import("./../src/pages/components/dialog/code.mdx" /* webpackChunkName: "component---src-pages-components-dialog-code-mdx" */),
  "component---src-pages-components-dialog-design-mdx": () => import("./../src/pages/components/dialog/design.mdx" /* webpackChunkName: "component---src-pages-components-dialog-design-mdx" */),
  "component---src-pages-components-divider-code-mdx": () => import("./../src/pages/components/divider/code.mdx" /* webpackChunkName: "component---src-pages-components-divider-code-mdx" */),
  "component---src-pages-components-divider-design-mdx": () => import("./../src/pages/components/divider/design.mdx" /* webpackChunkName: "component---src-pages-components-divider-design-mdx" */),
  "component---src-pages-components-fieldset-code-mdx": () => import("./../src/pages/components/fieldset/code.mdx" /* webpackChunkName: "component---src-pages-components-fieldset-code-mdx" */),
  "component---src-pages-components-fieldset-design-mdx": () => import("./../src/pages/components/fieldset/design.mdx" /* webpackChunkName: "component---src-pages-components-fieldset-design-mdx" */),
  "component---src-pages-components-flex-code-mdx": () => import("./../src/pages/components/flex/code.mdx" /* webpackChunkName: "component---src-pages-components-flex-code-mdx" */),
  "component---src-pages-components-heading-code-mdx": () => import("./../src/pages/components/heading/code.mdx" /* webpackChunkName: "component---src-pages-components-heading-code-mdx" */),
  "component---src-pages-components-heading-design-mdx": () => import("./../src/pages/components/heading/design.mdx" /* webpackChunkName: "component---src-pages-components-heading-design-mdx" */),
  "component---src-pages-components-hidden-code-mdx": () => import("./../src/pages/components/hidden/code.mdx" /* webpackChunkName: "component---src-pages-components-hidden-code-mdx" */),
  "component---src-pages-components-icon-code-mdx": () => import("./../src/pages/components/icon/code.mdx" /* webpackChunkName: "component---src-pages-components-icon-code-mdx" */),
  "component---src-pages-components-icon-design-mdx": () => import("./../src/pages/components/icon/design.mdx" /* webpackChunkName: "component---src-pages-components-icon-design-mdx" */),
  "component---src-pages-components-icon-icons-mdx": () => import("./../src/pages/components/icon/icons.mdx" /* webpackChunkName: "component---src-pages-components-icon-icons-mdx" */),
  "component---src-pages-components-iconbutton-code-mdx": () => import("./../src/pages/components/iconbutton/code.mdx" /* webpackChunkName: "component---src-pages-components-iconbutton-code-mdx" */),
  "component---src-pages-components-iconbutton-design-mdx": () => import("./../src/pages/components/iconbutton/design.mdx" /* webpackChunkName: "component---src-pages-components-iconbutton-design-mdx" */),
  "component---src-pages-components-illustration-code-mdx": () => import("./../src/pages/components/illustration/code.mdx" /* webpackChunkName: "component---src-pages-components-illustration-code-mdx" */),
  "component---src-pages-components-illustration-illustrations-mdx": () => import("./../src/pages/components/illustration/illustrations.mdx" /* webpackChunkName: "component---src-pages-components-illustration-illustrations-mdx" */),
  "component---src-pages-components-image-code-mdx": () => import("./../src/pages/components/image/code.mdx" /* webpackChunkName: "component---src-pages-components-image-code-mdx" */),
  "component---src-pages-components-image-design-mdx": () => import("./../src/pages/components/image/design.mdx" /* webpackChunkName: "component---src-pages-components-image-design-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-linktext-code-mdx": () => import("./../src/pages/components/linktext/code.mdx" /* webpackChunkName: "component---src-pages-components-linktext-code-mdx" */),
  "component---src-pages-components-linktext-design-mdx": () => import("./../src/pages/components/linktext/design.mdx" /* webpackChunkName: "component---src-pages-components-linktext-design-mdx" */),
  "component---src-pages-components-list-code-mdx": () => import("./../src/pages/components/list/code.mdx" /* webpackChunkName: "component---src-pages-components-list-code-mdx" */),
  "component---src-pages-components-list-design-mdx": () => import("./../src/pages/components/list/design.mdx" /* webpackChunkName: "component---src-pages-components-list-design-mdx" */),
  "component---src-pages-components-loader-code-mdx": () => import("./../src/pages/components/loader/code.mdx" /* webpackChunkName: "component---src-pages-components-loader-code-mdx" */),
  "component---src-pages-components-loader-design-mdx": () => import("./../src/pages/components/loader/design.mdx" /* webpackChunkName: "component---src-pages-components-loader-design-mdx" */),
  "component---src-pages-components-logo-code-mdx": () => import("./../src/pages/components/logo/code.mdx" /* webpackChunkName: "component---src-pages-components-logo-code-mdx" */),
  "component---src-pages-components-paper-code-mdx": () => import("./../src/pages/components/paper/code.mdx" /* webpackChunkName: "component---src-pages-components-paper-code-mdx" */),
  "component---src-pages-components-paper-design-mdx": () => import("./../src/pages/components/paper/design.mdx" /* webpackChunkName: "component---src-pages-components-paper-design-mdx" */),
  "component---src-pages-components-paragraph-code-mdx": () => import("./../src/pages/components/paragraph/code.mdx" /* webpackChunkName: "component---src-pages-components-paragraph-code-mdx" */),
  "component---src-pages-components-paragraph-design-mdx": () => import("./../src/pages/components/paragraph/design.mdx" /* webpackChunkName: "component---src-pages-components-paragraph-design-mdx" */),
  "component---src-pages-components-placeholder-code-mdx": () => import("./../src/pages/components/placeholder/code.mdx" /* webpackChunkName: "component---src-pages-components-placeholder-code-mdx" */),
  "component---src-pages-components-radio-code-mdx": () => import("./../src/pages/components/radio/code.mdx" /* webpackChunkName: "component---src-pages-components-radio-code-mdx" */),
  "component---src-pages-components-radio-design-mdx": () => import("./../src/pages/components/radio/design.mdx" /* webpackChunkName: "component---src-pages-components-radio-design-mdx" */),
  "component---src-pages-components-rich-content-code-mdx": () => import("./../src/pages/components/richContent/code.mdx" /* webpackChunkName: "component---src-pages-components-rich-content-code-mdx" */),
  "component---src-pages-components-screenreader-code-mdx": () => import("./../src/pages/components/screenreader/code.mdx" /* webpackChunkName: "component---src-pages-components-screenreader-code-mdx" */),
  "component---src-pages-components-select-code-mdx": () => import("./../src/pages/components/select/code.mdx" /* webpackChunkName: "component---src-pages-components-select-code-mdx" */),
  "component---src-pages-components-select-design-mdx": () => import("./../src/pages/components/select/design.mdx" /* webpackChunkName: "component---src-pages-components-select-design-mdx" */),
  "component---src-pages-components-stepper-code-mdx": () => import("./../src/pages/components/stepper/code.mdx" /* webpackChunkName: "component---src-pages-components-stepper-code-mdx" */),
  "component---src-pages-components-stepper-design-mdx": () => import("./../src/pages/components/stepper/design.mdx" /* webpackChunkName: "component---src-pages-components-stepper-design-mdx" */),
  "component---src-pages-components-switch-code-mdx": () => import("./../src/pages/components/switch/code.mdx" /* webpackChunkName: "component---src-pages-components-switch-code-mdx" */),
  "component---src-pages-components-switch-design-mdx": () => import("./../src/pages/components/switch/design.mdx" /* webpackChunkName: "component---src-pages-components-switch-design-mdx" */),
  "component---src-pages-components-table-code-mdx": () => import("./../src/pages/components/table/code.mdx" /* webpackChunkName: "component---src-pages-components-table-code-mdx" */),
  "component---src-pages-components-table-design-mdx": () => import("./../src/pages/components/table/design.mdx" /* webpackChunkName: "component---src-pages-components-table-design-mdx" */),
  "component---src-pages-components-text-code-mdx": () => import("./../src/pages/components/text/code.mdx" /* webpackChunkName: "component---src-pages-components-text-code-mdx" */),
  "component---src-pages-components-text-design-mdx": () => import("./../src/pages/components/text/design.mdx" /* webpackChunkName: "component---src-pages-components-text-design-mdx" */),
  "component---src-pages-components-textarea-code-mdx": () => import("./../src/pages/components/textarea/code.mdx" /* webpackChunkName: "component---src-pages-components-textarea-code-mdx" */),
  "component---src-pages-components-textarea-design-mdx": () => import("./../src/pages/components/textarea/design.mdx" /* webpackChunkName: "component---src-pages-components-textarea-design-mdx" */),
  "component---src-pages-components-textfield-code-mdx": () => import("./../src/pages/components/textfield/code.mdx" /* webpackChunkName: "component---src-pages-components-textfield-code-mdx" */),
  "component---src-pages-components-textfield-design-mdx": () => import("./../src/pages/components/textfield/design.mdx" /* webpackChunkName: "component---src-pages-components-textfield-design-mdx" */),
  "component---src-pages-components-tooltip-code-mdx": () => import("./../src/pages/components/tooltip/code.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-code-mdx" */),
  "component---src-pages-components-tooltip-design-mdx": () => import("./../src/pages/components/tooltip/design.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-design-mdx" */),
  "component---src-pages-components-use-media-query-code-mdx": () => import("./../src/pages/components/use-media-query/code.mdx" /* webpackChunkName: "component---src-pages-components-use-media-query-code-mdx" */),
  "component---src-pages-components-widelist-code-mdx": () => import("./../src/pages/components/widelist/code.mdx" /* webpackChunkName: "component---src-pages-components-widelist-code-mdx" */),
  "component---src-pages-components-widelist-design-mdx": () => import("./../src/pages/components/widelist/design.mdx" /* webpackChunkName: "component---src-pages-components-widelist-design-mdx" */),
  "component---src-pages-foundations-color-index-mdx": () => import("./../src/pages/foundations/color/index.mdx" /* webpackChunkName: "component---src-pages-foundations-color-index-mdx" */),
  "component---src-pages-foundations-elevation-index-mdx": () => import("./../src/pages/foundations/elevation/index.mdx" /* webpackChunkName: "component---src-pages-foundations-elevation-index-mdx" */),
  "component---src-pages-foundations-fonts-index-mdx": () => import("./../src/pages/foundations/fonts/index.mdx" /* webpackChunkName: "component---src-pages-foundations-fonts-index-mdx" */),
  "component---src-pages-foundations-iconography-index-mdx": () => import("./../src/pages/foundations/iconography/index.mdx" /* webpackChunkName: "component---src-pages-foundations-iconography-index-mdx" */),
  "component---src-pages-foundations-illustrations-index-mdx": () => import("./../src/pages/foundations/illustrations/index.mdx" /* webpackChunkName: "component---src-pages-foundations-illustrations-index-mdx" */),
  "component---src-pages-foundations-index-mdx": () => import("./../src/pages/foundations/index.mdx" /* webpackChunkName: "component---src-pages-foundations-index-mdx" */),
  "component---src-pages-foundations-logos-index-mdx": () => import("./../src/pages/foundations/logos/index.mdx" /* webpackChunkName: "component---src-pages-foundations-logos-index-mdx" */),
  "component---src-pages-foundations-spacing-index-mdx": () => import("./../src/pages/foundations/spacing/index.mdx" /* webpackChunkName: "component---src-pages-foundations-spacing-index-mdx" */),
  "component---src-pages-foundations-tokens-android-mdx": () => import("./../src/pages/foundations/tokens/android.mdx" /* webpackChunkName: "component---src-pages-foundations-tokens-android-mdx" */),
  "component---src-pages-foundations-tokens-css-mdx": () => import("./../src/pages/foundations/tokens/css.mdx" /* webpackChunkName: "component---src-pages-foundations-tokens-css-mdx" */),
  "component---src-pages-foundations-tokens-ios-mdx": () => import("./../src/pages/foundations/tokens/ios.mdx" /* webpackChunkName: "component---src-pages-foundations-tokens-ios-mdx" */),
  "component---src-pages-foundations-tokens-javascript-mdx": () => import("./../src/pages/foundations/tokens/javascript.mdx" /* webpackChunkName: "component---src-pages-foundations-tokens-javascript-mdx" */),
  "component---src-pages-foundations-tokens-scss-mdx": () => import("./../src/pages/foundations/tokens/scss.mdx" /* webpackChunkName: "component---src-pages-foundations-tokens-scss-mdx" */),
  "component---src-pages-getting-started-for-designers-index-mdx": () => import("./../src/pages/getting-started/for-designers/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-for-designers-index-mdx" */),
  "component---src-pages-getting-started-for-developers-index-mdx": () => import("./../src/pages/getting-started/for-developers/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-for-developers-index-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-getting-started-onboarding-a-brand-index-mdx": () => import("./../src/pages/getting-started/onboarding-a-brand/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-onboarding-a-brand-index-mdx" */),
  "component---src-pages-getting-started-styled-system-index-mdx": () => import("./../src/pages/getting-started/styled-system/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-styled-system-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-updates-0100-index-mdx": () => import("./../src/pages/updates/0100/index.mdx" /* webpackChunkName: "component---src-pages-updates-0100-index-mdx" */),
  "component---src-pages-updates-040-index-mdx": () => import("./../src/pages/updates/040/index.mdx" /* webpackChunkName: "component---src-pages-updates-040-index-mdx" */),
  "component---src-pages-updates-050-index-mdx": () => import("./../src/pages/updates/050/index.mdx" /* webpackChunkName: "component---src-pages-updates-050-index-mdx" */),
  "component---src-pages-updates-060-index-mdx": () => import("./../src/pages/updates/060/index.mdx" /* webpackChunkName: "component---src-pages-updates-060-index-mdx" */),
  "component---src-pages-updates-070-index-mdx": () => import("./../src/pages/updates/070/index.mdx" /* webpackChunkName: "component---src-pages-updates-070-index-mdx" */),
  "component---src-pages-updates-080-index-mdx": () => import("./../src/pages/updates/080/index.mdx" /* webpackChunkName: "component---src-pages-updates-080-index-mdx" */),
  "component---src-pages-updates-090-index-mdx": () => import("./../src/pages/updates/090/index.mdx" /* webpackChunkName: "component---src-pages-updates-090-index-mdx" */),
  "component---src-pages-updates-100-index-mdx": () => import("./../src/pages/updates/100/index.mdx" /* webpackChunkName: "component---src-pages-updates-100-index-mdx" */),
  "component---src-pages-updates-110-index-mdx": () => import("./../src/pages/updates/110/index.mdx" /* webpackChunkName: "component---src-pages-updates-110-index-mdx" */),
  "component---src-pages-updates-1100-index-mdx": () => import("./../src/pages/updates/1100/index.mdx" /* webpackChunkName: "component---src-pages-updates-1100-index-mdx" */),
  "component---src-pages-updates-120-index-mdx": () => import("./../src/pages/updates/120/index.mdx" /* webpackChunkName: "component---src-pages-updates-120-index-mdx" */),
  "component---src-pages-updates-140-index-mdx": () => import("./../src/pages/updates/140/index.mdx" /* webpackChunkName: "component---src-pages-updates-140-index-mdx" */),
  "component---src-pages-updates-150-index-mdx": () => import("./../src/pages/updates/150/index.mdx" /* webpackChunkName: "component---src-pages-updates-150-index-mdx" */),
  "component---src-pages-updates-160-index-mdx": () => import("./../src/pages/updates/160/index.mdx" /* webpackChunkName: "component---src-pages-updates-160-index-mdx" */),
  "component---src-pages-updates-170-index-mdx": () => import("./../src/pages/updates/170/index.mdx" /* webpackChunkName: "component---src-pages-updates-170-index-mdx" */),
  "component---src-pages-updates-changelog-index-mdx": () => import("./../src/pages/updates/changelog/index.mdx" /* webpackChunkName: "component---src-pages-updates-changelog-index-mdx" */),
  "component---src-pages-updates-logo-migration-index-mdx": () => import("./../src/pages/updates/logo-migration/index.mdx" /* webpackChunkName: "component---src-pages-updates-logo-migration-index-mdx" */),
  "component---src-pages-updates-migration-index-mdx": () => import("./../src/pages/updates/migration/index.mdx" /* webpackChunkName: "component---src-pages-updates-migration-index-mdx" */)
}

