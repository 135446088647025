import React from 'react';
import { Box, Icon, Text } from 'CHAMELEON_REACT_HUB';
import { useIcons } from '../../utils/multiBrandedTheme';

const IconExample = ({ name, value, label }) => {
  const { Brand } = useIcons();
  const sizes = {
    "iconLarge": "large",
    "iconMedium": "medium",
    "iconSmall": "small",
    "iconXLarge": "xLarge",
    "icon-medium": "medium",
    "icon-small": "small",
    "icon-large": "large",
    "icon-x-large": "xLarge",
    "icon_medium": "medium",
    "icon_small": "small",
    "icon_large": "large",
    "icon_x_large": "xLarge"
  }
  return (
    <Box>
      <Box><Icon as={Brand} color="colorGrey60" size={sizes[name]} /></Box>
      <Text color="colorGrey60" fontFamily="system" size='Caption2'>{label}</Text>
    </Box>
  )
}

export default IconExample;