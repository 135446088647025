import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useStyleguide } from '../../utils/multiBrandedTheme';
import groupBy from '../../utils/groupBy';
import { Table, Text, Box, Tooltip } from 'CHAMELEON_REACT_HUB';
import Code from '../Code';
import ColorExample from './ColorExample';
import SpaceExample from './SpaceExample';
import ShadowExample from './ShadowExample';
import FontExample from './FontExample';
import BorderRadiusExample from './BorderRadiusExample';
import OverlayExample from './OverlayExample';
import IconExample from './IconExample';
import MotionExample from './MotionExample';
import DateTimeExample from './DateTimeExample';

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const renderComponent = (
  { name, deprecated, deprecated_comment, comment, prefix = '', suffix = '' },
  scope
) => {
  return (
    <CopyToClipboard text={name}>
      <StyledBox>
        {deprecated ? (
          <Code>
            <Text decoration="line-through">
              {prefix}
              {name}
              {suffix}
            </Text>
          </Code>
        ) : (
          <Code>
            {prefix}
            {name}
            {suffix}
          </Code>
        )}
        {deprecated_comment && (
          <Text
            as="p"
            mt={2}
            size="Caption2"
            color="colorRed30"
            fontFamily="system"
          >
            {deprecated_comment}
          </Text>
        )}
        {scope !== 'zIndex' && comment && <Tooltip id={name} label={comment} />}
      </StyledBox>
    </CopyToClipboard>
  );
};

const renderExample = ({ name, value, comment, visualValue }, scope) => {
  switch (scope) {
    case 'color':
      return <ColorExample name={name} value={visualValue} label={value} />;
    case 'space':
      return <SpaceExample value={visualValue} label={value} />;
    case 'zIndex':
      return comment;
    case 'shadow':
      return <ShadowExample name={name} value={visualValue} label={value} />;
    case 'fontFamily':
      return <FontExample fontFamily={visualValue} label={value} />;
    case 'fontWeight':
      return <FontExample fontWeight={visualValue} label={value} />;
    case 'lineHeight':
      return <FontExample lineHeight={visualValue} label={value} />;
    case 'fontSize':
      return <FontExample fontSize={visualValue} label={value} />;
    case 'borderRadius':
      return <BorderRadiusExample value={visualValue} label={value} />;
    case 'overlay':
      return <OverlayExample value={visualValue} label={value} />;
    case 'motion':
      return <MotionExample value={visualValue} label={value} />;
    case 'icon':
      return <IconExample name={name} value={visualValue} label={value} />;
    case 'dateTime':
      return <DateTimeExample name={name} value={visualValue} label={value} />;
    default:
      return value;
  }
};

const renderData = (data, scope) => {
  return data.map(prop => {
    const item = {};
    item.component = renderComponent(prop, scope);
    item.example = renderExample(prop, scope);
    return item;
  });
};

const compareSpaceValues = (a, b) => a.original.value - b.original.value;

const TokenInfo = ({ platform, scope, prefix, suffix }) => {
  const styleguide = useStyleguide(platform);

  // return null;
  const values = groupBy(styleguide, obj => obj.path[0])[scope];
  const styleguideWeb = useStyleguide('js');
  const valuesWeb = groupBy(styleguideWeb, obj => obj.path[0])[scope];

  values.map((value, index) => {
    value.visualValue = valuesWeb[index].value;
    value.prefix = prefix;
    value.suffix = suffix;
    return value;
  });

  if (scope == 'space') {
    values.sort(compareSpaceValues);
  }

  return (
    <Table
      columns={[
        { title: 'Component', field: 'component' },
        { title: 'Example', field: 'example' },
      ]}
      data={renderData(values, scope)}
    />
  );
};

TokenInfo.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      comment: PropTypes.string,
      deprecated: PropTypes.bool,
      prefix: PropTypes.string,
      suffix: PropTypes.string,
    })
  ),
};

export default TokenInfo;
