import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.scss';

const Grid = ({ className, ...rest }) => (
  <div className={cx(styles.root, className)} {...rest} />
);

Grid.propTypes = {
  /** Use the visual styles of the Flex but render it as a different HTML tag or a different custom component. */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),

  /** Contents displayed within the button. */
  children: PropTypes.node,

  /** Extend classNames. */
  className: PropTypes.string,
};

Grid.propTypes = {};

Grid.defaultProps = {};

export default Grid;
