import React from 'react';
import PropTypes from 'prop-types';
import * as Illustrations from '../../images/illustrations';

const Illustration = ({ name }) => {
  const Illustration = Illustrations[name];

  return <Illustration />;
};

Illustration.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Illustration;
