import React from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import ThemeContextProvider from './src/components/ThemeContext/ThemeContextProvider';
import NavContextProvider from './src/components/NavContext/NavContextProvider';
import updateQueryString from './src/utils/updateQueryString';
import components from './mdx-components';

const TRACK_PERFORMANCE = false;

export const wrapRootElement = ({ element }) => (
  <ThemeContextProvider>
    <NavContextProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
    </NavContextProvider>
  </ThemeContextProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node,
};

export const onClientEntry = () => {
  if (TRACK_PERFORMANCE && process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.hash) {
    if (!document.querySelector(`${location.hash}`)) {
      return;
    }
    const item = document.querySelector(`${location.hash}`).offsetTop;

    window.scrollTo({
      top: item - 70,
    });
  }

  if (location && location.search === '' && prevLocation) {
    const lastTheme = window.localStorage.getItem('ch-theme');
    updateQueryString(lastTheme);
  }

  return true;
};
