import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Svg = styled.svg`
  max-width: 80vw;
  height: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointMedium}) {
    max-width: 50vw;
  }
`;

function Shape(props) {
  const brandTheme = useContext(ThemeContext);

  return (
    <Svg width={651} height={547} viewBox="0 0 651 547" fill="none" {...props}>
      <path
        d="M0 0h651v546.5L20.56 195.933A40 40 0 010 160.974V0z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={189.608}
          y1={5.921}
          x2={578.912}
          y2={522.466}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={brandTheme.colorPrimaryDark} />
          <stop offset={1} stopColor={brandTheme.colorPrimaryLight} />
        </linearGradient>
      </defs>
    </Svg>
  );
}

Shape.defaultProps = {
  colorStart: '#0B0972',
  colorEnd: '#3D3BA1',
};

export default Shape;
