import { useEffect, useLayoutEffect } from 'react';

const useIsomorphicEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect;

const useBodyScrollLock = active => {
  useIsomorphicEffect(() => {
    if (!active) {
      return undefined;
    }

    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount

    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [active]);
};

export default useBodyScrollLock;
