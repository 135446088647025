import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useComponents,
  useIcons,
  useIllustrations,
} from '../../utils/multiBrandedTheme';
import camelCaseToDash from '../../utils/camelCaseToDash';
import CopyButton from '../CopyButton';
import { useThemeContext } from '../ThemeContext';
import Columns from '../Columns';
import Empty from '../Empty';

const sortAsc = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const convertObjectToArray = obj =>
  Object.keys(obj).map(key => ({
    name: key,
    icon: obj[key],
  }));

const SVGGrid = ({ items, cols, height, type }) => {
  if (!items) {
    throw new Error('Please use the getItems function');
  }

  const [search, setSearch] = useState('');
  const {
    TextField,
    Icon,
    Illustration,
    Text,
    Box,
    Paper,
    IconButton,
    Tooltip,
    Flex,
    Caption,
  } = useComponents();
  const { Copy, Download, Search } = useIcons();
  const [theme] = useThemeContext();

  const values = convertObjectToArray(items).sort(sortAsc);
  const filteredValues = values.filter(({ name }) =>
    name.toLowerCase().includes(search.toLowerCase())
  );

  const download = async name => {
    const filename = camelCaseToDash(name);
    const file = await import(
      `@mediahuis/chameleon-theme-${theme}/lib/web/${type}s/${filename}.svg`
    ); // async -> we don't want to load all these files directly

    if (!file.default) {
      throw new Error(`This ${type} is not available, please let us know.`);
    }

    const element = document.createElement('a');

    element.setAttribute('href', `${file.default}`);
    element.setAttribute('download', `${filename}`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const getCodeSnippet = name => {
    if (type === 'icon') {
      return `<Icon as={${name}} />`;
    }

    return `<Illustration as={${name}} height="full" maxWidth="full" />`;
  };

  return (
    <div>
      <TextField
        labelHidden
        label={`Search ${type}s`}
        iconLeft={Search}
        id={`search-${type}s`}
        placeholder={`Search ${type}s...`}
        onChange={e => setSearch(e.target.value)}
        mb={7}
      />

      {filteredValues.length === 0 ? (
        <Empty textBefore={`No ${type}s matching: `} search={search} />
      ) : (
        <Columns
          cols={
            cols || {
              lg: '1fr 1fr 1fr 1fr',
              md: '1fr 1fr 1fr',
              sm: '1fr 1fr',
              xs: '1fr 1fr',
            }
          }
        >
          {filteredValues.map(({ name }) => {
            const as =
              type === 'icon' ? useIcons()[name] : useIllustrations()[name];
            return (
              <Paper
                key={name}
                position="relative"
                height={height || '150px'}
                borderStyle="solid"
                borderColor="grey10"
                borderWidth={1}
                borderRadius={2}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                pb={7}
              >
                {type === 'icon' ? (
                  <Icon color="grey80" as={as} />
                ) : (
                  <Illustration as={as} height="full" maxWidth="full" />
                )}
                <Box
                  position="absolute"
                  bottom={0}
                  width="full"
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  bgColor="grey10"
                  color="grey60"
                  py={2}
                  pl={3}
                  pr={1}
                  borderRadius={2}
                >
                  <Text mt={1} fontFamily="system" size="Caption2">
                    {name}
                  </Text>
                  <Flex alignItems="center">
                    <Tooltip label="Copy React Snippet">
                      <span>
                        <CopyButton copyText={getCodeSnippet(name)}>
                          {({ copied }) =>
                            copied ? (
                              <Caption>Copied</Caption>
                            ) : (
                              <IconButton size="small" icon={Copy} />
                            )
                          }
                        </CopyButton>
                      </span>
                    </Tooltip>
                    <Tooltip label="Download SVG">
                      <IconButton
                        size="small"
                        icon={Download}
                        onClick={() => download(name)}
                      />
                    </Tooltip>
                  </Flex>
                </Box>
              </Paper>
            );
          })}
        </Columns>
      )}
    </div>
  );
};

SVGGrid.propTypes = {
  items: PropTypes.object,
  cols: PropTypes.object,
  type: PropTypes.string,
};

SVGGrid.defaultProps = {
  type: 'icon',
};

export default SVGGrid;
