import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'gatsby';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import getLinkFromAbsolutePath from '../../utils/getLinkFromAbsolutePath'; // eslint-disable-line
import Query from '../../queries/navQuery';
import HomePageSection from '../HomePageSection';

const Dot = ({ appearance }) => {
  const { Flex, Box } = useComponents();

  if (appearance === 'primary') {
    return (
      <Flex
        width={7}
        height={7}
        borderWidth={2}
        borderColor="secondaryBase"
        borderStyle="solid"
        borderRadius="fill"
        bgColor="grey10"
        mr={7}
        justifyContent="center"
        alignItems="center"
      >
        <Box width={3} height={3} borderRadius="fill" bgColor="secondaryBase" />
      </Flex>
    );
  }

  return (
    <Flex
      width={7}
      height={7}
      bgColor="grey10"
      mr={7}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={6}
        height={6}
        borderRadius="fill"
        borderWidth={2}
        borderColor="grey50"
        borderStyle="solid"
        borderRadius="fill"
        bgColor="grey10"
      />
    </Flex>
  );
};

const Line = () => {
  const { Flex, Box } = useComponents();
  return (
    <Flex
      width={7}
      height={7}
      position="absolute"
      height="full"
      justifyContent="center"
    >
      <Box width={1} height="full" bgColor="grey50" />
    </Flex>
  );
};

const transformUpdates = menuItems => {
  return menuItems.find(item => item.mainPath.includes('updates'));
};

const Updates = () => {
  const { Heading, Text, Flex, Button, Box } = useComponents();
  const { ChevronForward } = useIcons();
  const brandTheme = useContext(ThemeContext);

  return (
    <Query
      render={menuItems => {
        const updates = transformUpdates(menuItems);
        const orderedUpdates = updates.items
          .filter(({ node }) => node.frontmatter.order)
          .sort((a, b) => {
            if (a.node.frontmatter.order < b.node.frontmatter.order) {
              return -1;
            }

            return 1;
          });

        return (
          <HomePageSection position="relative" pb={{ xs: 0, md: 0, lg: 0 }}>
            <Flex
              justifyContent="center"
              py={10}
              px={6}
              bgColor="grey10"
              borderRadius={5}
            >
              <Box width="500px">
                <Heading
                  fontFamily="primary"
                  fontWeight="medium"
                  level={2}
                  mb={5}
                  color="grey80"
                >
                  What's new
                </Heading>
                <Text color="grey60" size="Heading5" fontFamily="system">
                  Chameleon is an ever-evolving product. With each release, we
                  aim to further bridge the gap between design and development,
                  providing both designers and developers with the necessary
                  tools to ease their own workflow and improve cross-skill
                  communication.
                </Text>

                <Box position="relative">
                  <Line />

                  {orderedUpdates.slice(0, 3).map(({ node }, i) => {
                    return (
                      <Flex mt={7} position="relative" key={node.fileAbsolutePath}>
                        <Dot appearance={i === 0 ? 'primary' : undefined} />
                        <Link
                          to={`${getLinkFromAbsolutePath(node.fileAbsolutePath)}?brand=${brandTheme}`}
                        >
                          <Text
                            size="Heading5"
                            fontWeight="bold"
                            color="grey80"
                            mb={2}
                            display="block"
                            fontFamily="system"
                          >
                            {node.frontmatter.title}
                          </Text>
                          <Text
                            size="Caption2"
                            display="block"
                            fontWeight="medium"
                            color="secondaryBase"
                            fontFamily="system"
                            textTransform="uppercase"
                          >
                            {node.frontmatter.name}
                          </Text>
                        </Link>
                      </Flex>
                    );
                  })}
                  <Box
                    style={{
                      background: `linear-gradient(180deg, rgba(243, 244, 244, 0) 0%, ${brandTheme.colorGrey10} 87.5%)`,
                      pointerEvents: 'none',
                    }}
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    position="absolute"
                    borderRadius={5}
                  />
                </Box>

                <Flex justifyContent="center">
                  <Button
                    as={Link}
                    appearance="secondary"
                    to={updates.mainPath}
                    iconRight={ChevronForward}
                    size="small"
                  >
                    See all updates
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </HomePageSection>
        );
      }}
    />
  );
};

export default Updates;
