import React, { useState, useEffect, version } from 'react';
import Code from '../Code';
import { useThemeContext } from '../ThemeContext';
import {
  useStyleguide,
  useIcons,
  useComponents,
} from '../../utils/multiBrandedTheme';
import CopyButton from '../CopyButton';
import logoData from '../../config/logos';

const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);

const LogoPreview = () => {
  const [theme] = useThemeContext();
  const ts = Math.round(new Date().getTime() / 1000);
  const brandLogos = logoData[theme];
  const { baseUrl, activeVersion, baseTheme, ...versions } = brandLogos;
  const [selectedVersion, setSelectedVersion] = useState(activeVersion);

  const {
    Paper,
    Heading,
    Divider,
    Box,
    Flex,
    Button,
    Select,
  } = useComponents();

  useEffect(() => {
    setSelectedVersion(activeVersion);
  }, [activeVersion]);

  const [bgColor, setBgColor] = useState('colorWhiteBase');
  const styleGuide = useStyleguide('js');
  const { Share, ArrowDown } = useIcons();
  const tokenColors = styleGuide.filter(token => token.path[0] === 'color');
  const color = tokenColors.find(color => color.name === bgColor);
  const bgColorValue = color.value;

  const versionUrl = `${baseUrl}/${selectedVersion}/`;

  const downloadImage = fileName => {
    const filePath = `${versionUrl}${fileName}?updated=${ts}`;

    fetch(filePath)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        alert('Something went wrong when downloading your file.');
      });
  };

  return (
    <>
      <Box my={4}>
        <Code>Current active version: {activeVersion}</Code>
      </Box>
      <Flex justifyContent="space-between">
        <Select
          id="background"
          value={bgColor}
          onChange={e => setBgColor(e.target.value)}
          label="Background color"
          message="Check what logo works best on what background color"
        >
          <option value="colorWhiteBase">White</option>
          <option value="colorBlackBase">Black</option>
          <option value="colorPrimaryBase">Primary</option>
          <option value="colorSecondaryBase">Secondary</option>
          <option value="colorTertiaryBase">Tertiary</option>
        </Select>
        <Select
          id="version"
          value={selectedVersion}
          onChange={e => setSelectedVersion(e.target.value)}
          label="Version"
          message="Switch between versions"
        >
          {Object.keys(versions).map(version => (
            <option key={version} value={version}>
              {version}
            </option>
          ))}
        </Select>
      </Flex>
      {versions[selectedVersion] &&
        Object.keys(versions[selectedVersion]).map(category => (
          <div key={category}>
            <Box mt={7} mb={5}>
              <Heading level={2} color="primary100" fontFamily="primary">
                {capitalize(category)}
              </Heading>
            </Box>
            <Flex flexWrap="wrap">
              {Object.entries(versions[selectedVersion][category]).map(
                ([logoName, logo]) => (
                  <Box width="214px" mx={3} mb={5} key={logoName}>
                    <Paper>
                      <Box
                        height="200px"
                        p={9}
                        position="relative"
                        clone
                        style={{ backgroundColor: bgColorValue }}
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Box
                            as="img"
                            src={`${versionUrl}${logo.png}?updated=${ts}`}
                            alt={logo.alt || logoName}
                            maxWidth="full"
                          />
                          <Box position="absolute" bottom={3} left={3}>
                            <Heading
                              color="grey40"
                              fontWeight="regular"
                              level={6}
                            >
                              {logoName}
                            </Heading>
                          </Box>
                        </Flex>
                      </Box>
                      <Divider />
                      <Box bgColor="white" p={5}>
                        <Box mb={5}>
                          <Heading
                            color="primary100"
                            level={6}
                            fontWeight="regular"
                          >
                            Download
                          </Heading>
                          {logo.png && (
                            <Box my={2}>
                              <Button
                                iconLeft={ArrowDown}
                                size="small"
                                width="full"
                                appearance="secondary"
                                onClick={() => downloadImage(logo.png)}
                              >
                                PNG
                              </Button>
                            </Box>
                          )}
                          {logo.svg && (
                            <Box my={2}>
                              <Button
                                iconLeft={ArrowDown}
                                size="small"
                                width="full"
                                appearance="secondary"
                                onClick={() => downloadImage(logo.svg)}
                              >
                                SVG
                              </Button>
                            </Box>
                          )}
                        </Box>
                        <Box mb={5}>
                          <Heading
                            color="primary100"
                            level={6}
                            fontWeight="regular"
                          >
                            Copy
                          </Heading>
                          {logo.png && (
                            <CopyButton
                              copyText={`${versionUrl}${logo.png}?updated=${ts}`}
                              icon={Share}
                              text="PNG url"
                            />
                          )}
                          {logo.svg && (
                            <CopyButton
                              copyText={`${versionUrl}${logo.svg}?updated=${ts}`}
                              icon={Share}
                              text="SVG url"
                            />
                          )}
                        </Box>
                        <Box mb={5}>
                          <Heading
                            color="primary100"
                            level={6}
                            fontWeight="regular"
                          >
                            Generate
                          </Heading>
                          <CopyButton
                            copyText={`<img src="${versionUrl}${
                              logo.svg
                            }" alt="${logo.alt || logoName}" >`}
                            text="HTML &lt;img&gt; tag"
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )
              )}
            </Flex>
          </div>
        ))}
    </>
  );
};

export default LogoPreview;
