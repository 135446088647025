import React, { useState } from 'react';
import Blockquote from './src/components/Blockquote';
import CodePreview from './src/components/CodePreview';
import Section from './src/components/Section';
import DocsIllustration from './src/components/DocsIllustration';
import PagesOverview from './src/components/PagesOverview';
import Code from './src/components/Code';
import * as Chameleon from 'CHAMELEON_REACT_HUB';
import { useComponents, useIcons } from './src/utils/multiBrandedTheme';
import { useThemeContext } from './src/components/ThemeContext';
import canUseDom from './src/utils/canUseDom';
import generateUrlWithQueryString from './src/utils/generateUrlWithQueryString';
import { useTimeout } from './src/hooks';
import copyToClipboard from './src/utils/copyToClipboard';
const { Box, Flex } = Chameleon;

function shareableId(id) {
  if (id) {
    return `h-${id}`;
  }

  return undefined;
}

/* eslint-disable react/display-name */
const components = {
  // Heading 1 #
  h1: ({ id, ...rest }) => {
    const { Heading } = useComponents();
    return (
      <Heading
        mb={7}
        fontFamily="primary"
        level={1}
        color="grey80"
        id={shareableId(id)}
        {...rest}
      />
    );
  },
  // Heading 2 ##
  h2: ({ id, ...rest }) => {
    const TIME = 2500;
    const [isShown, setIsShown] = useState(false);
    const [copied, setCopied] = React.useState(false);
    const { Heading, Text } = useComponents();
    const [theme] = useThemeContext();
    const url = canUseDom()
      ? generateUrlWithQueryString(theme, shareableId(id))
      : '';

    useTimeout(() => setCopied(false), copied ? TIME : null);

    function copy() {
      copyToClipboard(url).then(() => {
        setCopied(true);
      });
    }

    return (
        <Flex
          mt={10}
          mb={7}
          position="relative"
          alignItems="center"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown && (
            <Box
              onClick={copy}
              left={0}
              position="absolute"
              px={3}
              style={{
                cursor: 'pointer',
                transform: 'translate(-100%, -50%)',
                top: '50%',
              }}
            >
              <Text
                fontFamily="primary"
                size="Heading2"
                fontWeight="bold"
                color="colorPrimary70"
              >
                #
              </Text>
            </Box>
          )}
          <Heading
            fontFamily="primary"
            level={2}
            mr={2}
            color="grey80"
            id={shareableId(id)}
            {...rest}
          />
          {copied && (
            <Text
              fontFamily="primary"
              size={copied ? 'Caption2' : 'Heading2'}
              fontWeight="bold"
              color="colorGreyBase"
            >
              copied!
            </Text>
          )}
        </Flex>
    );
  },
  // Heading 3 ###
  h3: ({ id, ...rest }) => {
    const { Heading } = useComponents();
    return (
      <Heading
        mt={9}
        mb={6}
        fontFamily="primary"
        level={3}
        color="grey80"
        id={shareableId(id)}
        {...rest}
      />
    );
  },
  // Heading 4 ####
  h4: ({ id, ...rest }) => {
    const { Heading } = useComponents();
    return (
      <Heading
        mt={9}
        mb={6}
        fontFamily="primary"
        level={4}
        color="grey80"
        id={shareableId(id)}
        {...rest}
      />
    );
  },
  // Heading 5 #####
  h5: ({ id, ...rest }) => {
    const { Heading } = useComponents();
    return (
      <Heading
        mt={9}
        mb={6}
        fontFamily="primary"
        level={5}
        color="grey80"
        id={shareableId(id)}
        {...rest}
      />
    );
  },
  // Heading 6 ######
  h6: ({ id, ...rest }) => {
    const { Heading } = useComponents();
    return (
      <Heading
        mt={9}
        mb={6}
        fontFamily="primary"
        level={6}
        color="grey80"
        id={shareableId(id)}
        {...rest}
      />
    );
  },
  // Paragraph
  p: ({ id, ...rest }) => {
    const { Paragraph } = useComponents();
    return (
      <Paragraph
        mt={0}
        mb={6}
        {...rest}
        fontFamily="system"
        color="grey80"
        id={shareableId(id)}
      />
    );
  },
  // Anchor Link
  a: props => {
    const { LinkText } = useComponents();
    return <LinkText branded {...props} />;
  },
  // Blockquote >
  blockquote: props => {
    const { Box } = useComponents();
    return (
      <Box my={4}>
        <Blockquote {...props}></Blockquote>
      </Box>
    );
  },
  ul: props => {
    const { List } = useComponents();
    return (
      <Box mb={6}>
        <List {...props} />
      </Box>
    );
  },
  li: props => {
    const { ListItem } = useComponents();
    const { Bullet } = useIcons();
    return <ListItem {...props} icon={Bullet} iconColor="secondaryBase" />;
  },
  hr: props => {
    const { Box, Divider } = useComponents();
    return (
      <Box py={1} mt={6} mb={6}>
        <Divider {...props} />
      </Box>
    );
  },
  strong: props => <strong style={{ fontWeight: 'bold' }} {...props}></strong>,
  inlineCode: Code,
  pre: CodePreview,
  Checkbox: props => {
    const { Box, Checkbox } = useComponents();
    return (
      <Box mb={3}>
        <Checkbox {...props} />
      </Box>
    );
  },
  Section,
  DocsIllustration,
  PagesOverview,
};

export default components;
