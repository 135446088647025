import React from 'react';
import PropTypes from 'prop-types';
import { useStyleguide, useComponents } from '../../utils/multiBrandedTheme';
import SwatchItem from './SwatchItem';

const ColorSwatch = ({ colorName, tokenName, textTokenName, usage }) => {
  const js = useStyleguide('js');
  const tokenColors = js.filter(token => token.path[0] === 'color');
  const color = tokenColors.find(color => color.name === tokenName);
  const colorText = tokenColors.find(color => color.name === textTokenName);

  const { Box, Flex, Heading, Caption } = useComponents();

  if (!color) {
    return null;
  }

  return (
    <Box
      bgColor="colorWhiteBase"
      borderRadius={3}
      borderColor="grey20"
      borderStyle="solid"
      borderWidth={1}
      p={6}
    >
      <Flex
        height="100px"
        bgColor={color.name}
        borderRadius={3}
        alignItems="center"
        justifyContent="center"
        mb={6}
      >
        <Heading fontFamily="primary" level={4}>
          {colorName}
        </Heading>
      </Flex>
      {usage && (
        <Caption mt={5} color="grey60">
          Usage: {usage}
        </Caption>
      )}

      <SwatchItem mt={5} label="Token" value={color.name} hex={color.value} />
      <SwatchItem mt={2} value={color.value} />
      <SwatchItem mt={5} label="Text Token" value={textTokenName} />
      <SwatchItem mt={2} value={colorText.value} />
    </Box>
  );
};

ColorSwatch.propTypes = {
  colorName: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
  usage: PropTypes.string,
};

export default ColorSwatch;
