import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import groupBy from '../../utils/groupBy'; // eslint-disable-line
import { useStyleguide, useComponents } from '../../utils/multiBrandedTheme';
import ColorItem from './ColorItem';

const ColorGrid = ({ name }) => {
  const js = useStyleguide('js');
  const { Paper } = useComponents();
  const tokenColors = js.filter(token => token.path[0] === 'color');
  const colors = groupBy(tokenColors, obj => obj.path[1]);

  if (!colors) {
    return null;
  }

  const colorGroup = colors[`${name}`];
  const textColors = colors.text;

  if (!colorGroup) {
    return null;
  }

  const Grid = styled(Paper)`
    overflow: hidden;
    grid-template-areas:
        'c-base c-10'
        'c-base c-20'
        'c-base c-30'
        'c-base c-40'
        'c-base c-50'
        'c-base c-60'
        'c-light c-70'
        'c-light c-80'
        'c-dark c-90'
        'c-dark c-100';
    }

    @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointSmall}) {
      grid-template-areas:
        'c-base c-base c-10'
        'c-base c-base c-20'
        'c-base c-base c-30'
        'c-base c-base c-40'
        'c-base c-base c-50'
        'c-base c-base c-60'
        'c-base c-base c-70'
        'c-light c-dark c-80'
        'c-light c-dark c-90'
        'c-light c-dark c-100';
    }
  `;

  return (
    <Grid width="full" borderRadius={3} display="grid">
      {colorGroup.map(c => {
        const textColor =
          textColors.find(
            tc => tc.path[2] === name && c.path[2] === tc.path[3]
          ) || textColors.find(tc => tc.path[2] === c.path[2]);

        return (
          <ColorItem key={c.name} color={c} textColor={textColor} name={name} />
        );
      })}
    </Grid>
  );
};

ColorItem.propTypes = {
  color: PropTypes.shape({
    value: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  textColor: PropTypes.shape({
    value: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
};

ColorGrid.propTypes = {
  name: PropTypes.string,
};

ColorGrid.defaultProps = {};

export default ColorGrid;
