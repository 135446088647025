import React from 'react';
import IcoMultibranding from './IcoMultibranding';
import IcoConsistency from './IcoConsistency';
import IcoCrossPlatform from './IcoCrossPlatform';
import { useComponents } from '../../utils/multiBrandedTheme';
import Columns from '../Columns';
import HomePageSection from '../HomePageSection';

const Shadow = ({ children }) => {
  const { Paper } = useComponents();
  return (
    <Paper
      borderRadius={3}
      width="48px"
      height="48px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="secondaryBase"
      mb={4}
    >
      {children}
    </Paper>
  );
};

const Card = ({ icon, title, to, children }) => {
  const { Flex, Box, Paragraph, Icon, Heading } = useComponents();

  return (
    <Flex as="article" flexDirection="column" justifyContent="space-between">
      <Box>
        <Shadow>{icon}</Shadow>
        <Heading color="grey80" level={4} fontFamily="primary">
          {title}
        </Heading>
        <Paragraph color="grey60">{children}</Paragraph>
      </Box>
    </Flex>
  );
};

const Why = () => {
  const { Heading, Flex, Box } = useComponents();
  return (
    <HomePageSection>
      <Heading
        level={2}
        color="grey80"
        fontFamily="primary"
        textAlign="center"
        // mt={9}
        mb={5}
      >
        Why Chameleon
      </Heading>
      <Flex justifyContent="center">
        <Box width="500px" mb={10}>
          <Heading
            level={5}
            as="h3"
            color="grey60"
            textAlign="center"
            fontWeight="medium"
          >
            Everything you need to start designing and building Mediahuis
            products in one system.
          </Heading>
        </Box>
      </Flex>
      <Columns>
        <Card
          title="Multibranded"
          icon={<IcoMultibranding />}
          to="/about/multibranding"
        >
          Use exactly the same codebase when building for all of Mediahuis'
          brands.
        </Card>
        <Card
          title="Cross-platform"
          icon={<IcoCrossPlatform />}
          to="/foundations/tokens/javascript"
        >
          Ensure all branding values across all platforms are exactly the same,
          to provide users with a unified experience.
        </Card>
        <Card title="Consistency" icon={<IcoConsistency />} to="/about/vision">
          Not only cross-platform consistency is important, but so is
          cross-product consistency.
        </Card>
      </Columns>
    </HomePageSection>
  );
};

export default Why;
