import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useComponents } from '../../utils/multiBrandedTheme';

const Code = ({ children }) => {
  const { Box, Text } = useComponents();
  return (
    <Box
      as="span"
      py={1}
      px={2}
      bgColor="colorPrimary10"
      borderRadius={1}
      color="colorPrimary90"
      style={{ fontFamily: "'Fira Code', monospace", overflowWrap: 'normal' }}
    >
      <Text size="Caption2">{children}</Text>
    </Box>
  );
};

export const CopyCode = ({ children, copyText }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 1000);

      // eslint-disable-next-line
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={children || copyText} onCopy={() => setCopied(true)}>
      <Code
        style={{ cursor: copied ? 'default' : 'pointer', position: 'relative' }}
      >
        {children}
        {copied && (
          <Box
            position="absolute"
            left={0}
            zIndex={100}
            p={2}
            borderRadius={1}
            bgColor="tertiaryBase"
          >
            <Text size="Caption2" fontFamily="system">
              Copied!
            </Text>
          </Box>
        )}
      </Code>
    </CopyToClipboard>
  );
};

export default Code;
