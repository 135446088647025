import React from 'react';
import { Text, Table } from 'CHAMELEON_REACT_HUB';
import { useStyleguide } from '../../utils/multiBrandedTheme';

const FontExample = ({ activeVersion }) => {
  const styleGuide = useStyleguide('js');

  const activePrimaryFontToken = styleGuide.find(
    ({ name }) => name === 'fontFamilyBrandPrimary'
  );

  const activeSecondaryFontToken = styleGuide.find(
    ({ name }) => name === 'fontFamilyBrandSecondary'
  );
  const activeSystemFontToken = styleGuide.find(
    ({ name }) => name === 'fontFamilySystem'
  );

  return (
    <Table
      columns={[
        { title: 'EXAMPLE', field: 'example' },
        { title: 'VERSION', field: 'version', textAlign: 'right' },
      ]}
      data={[
        {
          example: (
            <>
              <Text
                size="Heading1"
                as="h2"
                style={{
                  fontFamily:
                    activePrimaryFontToken && activePrimaryFontToken.value,
                }}
              >
                The quick brown fox jumps over the lazy dog
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Name
              </Text>
              <Text size="Caption2" color="grey60" mb={3} display="block">
                Primary
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Value
              </Text>
              <Text size="Caption2" color="grey60" mb={3} display="block">
                {activePrimaryFontToken && activePrimaryFontToken.value}
              </Text>
            </>
          ),
          version: activeVersion,
        },
        {
          example: (
            <>
              <Text
                size="Heading1"
                style={{
                  fontFamily:
                    activeSecondaryFontToken && activeSecondaryFontToken.value,
                }}
                as="h2"
              >
                The quick brown fox jumps over the lazy dog
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Name
              </Text>
              <Text size="Caption2" color="grey60" mb={3} display="block">
                Secondary
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Value
              </Text>
              <Text mt={2} size="Caption2" color="grey60" display="block">
                {activeSecondaryFontToken && activeSecondaryFontToken.value}
              </Text>
            </>
          ),
          version: activeVersion,
        },
        {
          example: (
            <>
              <Text
                size="Heading1"
                style={{
                  fontFamily:
                    activeSystemFontToken && activeSystemFontToken.value,
                }}
                as="h2"
              >
                The quick brown fox jumps over the lazy dog
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Name
              </Text>
              <Text size="Caption2" color="grey60" mb={3} display="block">
                System
              </Text>
              <Text mt={4} size="Caption2" fontWeight="bold" display="block">
                Value
              </Text>
              <Text mt={2} size="Caption2" color="grey60" display="block">
                {activeSystemFontToken && activeSystemFontToken.value}
              </Text>
            </>
          ),
          version: activeVersion,
        },
      ]}
      emptyDescription="No fonts found"
    />
  );
  s;
};

export default FontExample;
