import React from 'react';
import styled from 'styled-components';
import { Caption, Paragraph, Heading, Flex, Box } from 'CHAMELEON_REACT_HUB';

const Member = ({ image, name, position, description }) => {
  return (
    <div>
      <Box mb={3} clone>
        <Flex alignItems="center">
          <img
            src={image}
            alt={name}
            style={{
              width: '40px',
              height: '40px',
              marginRight: '20px',
              borderRadius: '50%',
              filter: 'grayscale(100%)',
            }}
          />
          <div>
            <Heading level={4}>{name}</Heading>
            <Caption color="grey60">{position}</Caption>
          </div>
        </Flex>
      </Box>
      <Paragraph color="grey50">{description}</Paragraph>
    </div>
  );
};

export default Member;
