import React from 'react';
import styled from 'styled-components';
import { useComponents } from '../../utils/multiBrandedTheme';

const Section = ({ pt, pb, ...rest }) => {
  const { Box } = useComponents();

  const Wrapper = styled(Box)`
    padding-top: ${({ theme }) =>
      pt && `${pt.xs}` ? theme[`space${pt.xs}`] : 0};
    padding-bottom: ${({ theme }) =>
      pb && `${pb.xs}` ? theme[`space${pb.xs}`] : theme.space11};

    @media only screen and (min-width: ${({ theme }) =>
        theme.gridBreakpointMedium}) {
      padding-top: ${({ theme }) =>
        pt && `${pt.md}` ? theme[`space${pt.md}`] : 0};
      padding-bottom: ${({ theme }) =>
        pb && `${pb.md}` ? theme[`space${pb.md}`] : theme.space11};
    }

    @media only screen and (min-width: ${({ theme }) =>
        theme.gridBreakpointLarge}) {
      padding-top: ${({ theme }) =>
        pt && `${pt.lg}` ? theme[`space${pt.lg}`] : theme.space11};
      padding-bottom: ${({ theme }) =>
        pb && `${pb.lg}` ? theme[`space${pb.lg}`] : theme.space11};
    }
  `;

  return <Wrapper {...rest} />;
};

export default Section;
