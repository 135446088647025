import React from 'react';
import { Box, DateTime, Text } from 'CHAMELEON_REACT_HUB';

const DateTimeExample = ({ value, label }) => {
  return (
    <Box>
      <Box>
        <DateTime format={value} />
      </Box>
      <Text color="colorGrey60" fontFamily="system" size="Caption2">
        {label}
      </Text>
    </Box>
  );
};

export default DateTimeExample;
